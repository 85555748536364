$base-100: #5C5C5C;
$base-200: #525252;
$base-300: #474747;
$base-400: #3D3D3D;
$base: #333333;
$base-600: #292929;
$base-700: #1F1F1F;
$base-800: #141414;
$base-900: #0A0A0A;
$on-base: #FFFFFF;
$on-base-light: #B1B2B2;
$on-base-dark: #A2A4A4;


// primary(1 => #000000) secondary(2 => #1E1E1E) tertiary(3 => #DAFF00)
$merky-fc-solid-black: #000;
$merky-fc-light-black: #1E1E1E;
$merky-fc-gray: #B3B4B4;
$merky-fc-solid-white: #FFF;
$on-merky-fc-solid-white: #282727;
$merky-fc-yellow: #DAFF00;


$black: #000000;
$white: #FFFFFF;

$primary: #000000;
$on-primary: #B3B4B4;
$on-primary-light: #FFFFFF;

$secondary: #1E1E1E;
$on-secondary: #FFFFFF;
$on-secondary-dark: #B3B4B4;

//shades 100:#EDFF85, 200:#E3FF47, 300:#E0FF33, 400:#DDFF1F, #DAFF00;600:#D0F500, 700:#BFE000, 800:#ADCC00, 900:#9CB800
$tertiary: #DAFF00;
$on-tertiary: #1E1E1E;
$on-tertiary-dark: #000000;

$modal-primary: #FDFDFD;
$on-modal-primary: #282727;

$selection: #EDFF85;
$on-selection: #141414;
$border-black: #000000;
$border-light-black: #363636; //rgba(54, 54, 54, 0);
$border-light-black-60: #36363699; //rgba(54, 54, 54, 0.60);
$border-light-black-80: #363636CC; //rgba(54, 54, 54, 0.80);
$border-gray: #B3B4B4;// rgba(179, 180, 180, 1);
$border-gray-33: #B3B4B454;// rgba(179, 180, 180, 0.33);
$link: #DAFF00;

// $error-100: #F99F9F;
// $error-200: #F88C8C;
// $error-300: #F67979;
// $error-400: #F56666;
$error: #F34848;
// $error-600: #F33F3F;
// $error-700: #F22C2C;
// $error-800: #F60F0F;
// $error-900: #D30D0D;
// $on-error: #FFFFFF;
// $on-error-light: #E5E5E5;
// $on-error-dark: #D6D6D6;