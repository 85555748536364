@import 'src/styles/_variables.scss';

.header-wrapper {
    position: relative;
    padding: 15px 18px 18px;
    width: 100%;
    height: 52px;
    background-color: $primary;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.45);

    .brand-wrapper {
        padding-top: 4px;
        display: flex;
        align-items: center;
        color: $on-primary;
        font-size: 18px;
        font-style: normal;
        line-height: 1; /* 14.94px */
        letter-spacing: 1.8px;
        text-transform: uppercase;
        text-decoration: none;

        img {
            width: 30px;
            aspect-ratio: 1 / 0.7;
            margin-right: 10px;
            margin-top: -7px;
        }

        .brand-name {
            font-family: $bff-itc-fgs-demi;
            font-weight: 600;

            .brand-name-right {
                font-family: $bff-itc-fgs-book;
                font-weight: 400;
            }
        }
        
    }

    .nav-container {
        height: 19px;
        position: unset;

        .nav-menu-icon {
            padding-top: 5px;
            height: 100%;
            display: flex;
            align-items: center;
            color: $on-primary;
            font-family: $bff-itc-fgs-demi;
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            line-height: 83%; /* 10.79px */
            letter-spacing: 1.3px;
            text-transform: uppercase;
            cursor: pointer;
            &:hover {
                color: $link;
            }
        }

        .nav-menu-wrapper {
            display: none;
            flex-direction: row;
            gap: 0 37px;

            .nav-menu-close-btn-wrapper {
                position: absolute;
                top: 15px;
                right: 10px;
                height: 32px;
                width: 32px;
                cursor: pointer;

                &:hover {
                    svg path {
                        fill: $link
                    }
                }
            }

            .nav-menu-link {
                color: $merky-fc-light-black;
                text-align: center;
                font-family: $bff-itc-fgs-demi;
                font-size: 70px;
                font-style: normal;
                font-weight: 600;
                line-height: 83%; /* 58.1px */
                letter-spacing: -0.7px;
                text-transform: uppercase;
                text-decoration-line: none;
                cursor: pointer;

                &:hover {
                    color: $link;
                }
                .nav-menu-link-icon {
                    display: none;
                    width: 14px;
                    height: 14px;
                }

                @media screen and (max-width: 374px) {
                    font-size: 60px;
                    height: 42px;
                }
            }
        }
    }

}

@include device(xs, s, m) {
    .header-wrapper {

        .brand-wrapper {
            padding-top: 5px;

            img {
                width: 20px;
                aspect-ratio: 1 / 0.7;
                margin-right: 5px;
                margin-top: -6px;
            }

            .brand-name,
            .brand-name-right {
                font-size: 13px;
                letter-spacing: 1.3px;
            }
        }

        .nav-container {
            .nav-menu-wrapper {
                &.m-nav-menu-wrapper {
                    padding: 75px 10px 10px;
                    width: 100%;
                    height: 100vh;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 9;
                    display: flex;
                    flex-direction: column;
                    gap: 18px 0;
                    background: $merky-fc-gray;
                    overflow-y: auto;
                }

                .nav-menu-link {
                    height: 49px;
                    line-height: 1;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    &.logout-link {
                        order: 1;
                    }
                }
            }
        }
    }
}

@include device(l, xl, xxl, xxxl) {
    .header-wrapper {
        padding: 12px 25px 11px 27px;
        height: 76px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.45);

        .nav-container {
            height: 53px;
            display: flex;
            align-items: center;

            .nav-menu-icon {
                display: none;
            }

            .nav-menu-wrapper {
                height: 100%;
                display: flex;
                align-items: center;

                .nav-menu-close-btn-wrapper {
                    display: none;
                }

                .nav-menu-link {
                    padding-top: 5px;
                    height: 60%;
                    color: $merky-fc-gray;
                    text-align: center;
                    font-family: $bff-itc-fgs-demi;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 83%; /* 10.79px */
                    letter-spacing: 1.3px;
                    text-transform: uppercase;
                    display: flex;
                    align-items: center;

                    &.p-0 {
                        padding: 0;
                    }

                    .nav-menu-link-label {
                        display: none;
                    }
                    .nav-menu-link-icon {
                        display: block;
                    }
                    &:hover {
                        .nav-menu-link-icon path {
                            &:nth-child(-n + 2) {
                                fill: $link;
                            }
                            &:last-child {
                                stroke: $link;
                            }
                        }
                    }
                }
            }
        }
    }
}