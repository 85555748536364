@import 'src/styles/_variables.scss';

.reset-pass-container {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    background-color: $primary;

    .reset-pass-wrapper {
        margin: 0 auto;
        min-width: 320px;
        width: 100%;
        max-width: 470px;
        height: 100%;


        .reset-pass-form {
            padding: 80px 20px 40px;
            @include dashedBorder(b);

            .reset-pass-form-title {
                margin: 0 0 50px;
                color: $on-primary-light;
                font-family: $bff-itc-fgs-demi;
                font-size: 45px;
                font-style: normal;
                font-weight: 600;
                line-height: 83%; /* 37.35px */
                letter-spacing: -0.45px;
                text-transform: uppercase;
            }

            .reset-pass-form-body {
                margin: 0 0 35px;
                display: grid;
                gap: 16px 0;

                .reset-pass-form-body-item {
                    width: 100%;
                    height: 40px;

                    .reset-pass-form-body-item-input {
                        padding: 8px 20px 4px;
                        width: 100%;
                        height: 100%;
                        border-radius: 12px;
                        border: 3px solid $border-light-black;
                        outline: 0;
                        background: $secondary;
                        color: $on-secondary;
                        font-family: $bff-itc-fgs-book;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 83%; /* 14.94px */
                        letter-spacing: 1.8px;

                        &::placeholder, &::-webkit-input-placeholder {
                            text-transform: uppercase;
                        }

                        &[type="password"]:not(:placeholder-shown) {
                            letter-spacing: 5px;
                        }
                    }
                }

                .reset-pass-form-body-item-err-msg {
                    margin: 6px 0 0 8px;
                    color: $error;
                    opacity: 0.7;
                    font-family: $bff-peregrin-denton-light;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.24px;
                }
            }

            .reset-pass-form-note {
                color: $merky-fc-gray;
                font-family: $bff-peregrin-denton-light;
                font-size: 12px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
                letter-spacing: 0.6px;
            }
        }

        .reset-pass-action-wrapper {
            padding: 55px 20px 100px;
            user-select: none;
            -webkit-user-select: none;

            .reset-pass-action {
                width: 100%;
                height: 45px;

                .reset-pass-action-btn {
                    padding: 4px 0 0;
                    width: 100%;
                    height: 100%;
                    color: $on-secondary-dark;
                    font-family: $bff-itc-fgs-book;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 83%; /* 14.94px */
                    letter-spacing: 1.8px;
                    text-transform: uppercase;
                    outline: 0;
                    cursor: pointer;
                    background: $secondary;
                    border-radius: 16px;
                    border: 3px solid $border-light-black;

                    &.in-valid {
                        opacity: 0.6;
                        cursor: not-allowed;
                    }
                }
            }
        }

        .reset-pass-form-err-msg {
            margin: 0 0 10px;
            color: $error;
            opacity: 0.7;
            font-family: $base-font-family-ad;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.24px;
        }

        @include device(xs, s, m) {
            .reset-pass-form {
                .reset-pass-form-header {
                    margin: 0 0 50px;

                    .reset-pass-form-header-title {
                        font-size: 35px;
                        letter-spacing: -0.35px;
                    }

                    .reset-pass-form-header-info {
                        font-size: 16px;
                        line-height: normal;
                        letter-spacing: 0.32px;
                    }
                }

                .reset-pass-form-body {
                    .reset-pass-form-body-item {
                        .reset-pass-form-body-item-input {
                            font-size: 16px;
                            letter-spacing: 0.32px;
                        }
                    }
                }
            }
        }
    }
}