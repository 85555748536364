@import 'src/styles/variables';

.app-container {
  position: relative;
  // adjust the maximum view area of screen
  padding: 0 calc((100vw - 1440px) / 2);
  width: calc(100vw - (100vw - 100%));
  height: auto;
  min-height: calc(100vh);

  .app-header {
    position: sticky;
    top: -1px;
    left: 0;
    right: 0;
    z-index: 99;
  }

  .app-body-footer-wrapper {
    display: grid;
    grid-template-rows: 1fr max-content;
    width: 100%;
    height: auto;
    min-height: calc(100vh - 53px);

    .app-body {
      width: 100%;
      height: auto;
    }

    .app-footer {
      width: 100%;
      height: auto;
    }
  }
}

.popup-modal-component-wrapper {
  // width: calc(100vw - (100vw - 100%));
  width: 100%;
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.9);
  z-index: 999;
}

@include device(xl, xxl, xxxl) {

  .app-container {
    .app-body-footer-wrapper {
      min-height: calc(100vh - 77px);
    }
  }

}

/* width */
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 5px grey;
  background-color: whitesmoke;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #A2A4A4;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #B1B2B2;
}