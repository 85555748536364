@font-face {
    font-family: adihaus-din;
    src: url('../assets/fonts/AdihausDIN-Regular.ttf');
}
@font-face {
    font-family: adihaus-din-medium;
    src: url('../assets/fonts/AdihausDIN-Medium.ttf');
}
@font-face {
    font-family: adihaus-din-bold;
    src: url('../assets/fonts/AdihausDIN-Bold.ttf');
}

@font-face {
    font-family: adineue-chop-black;
    src: url('../assets/fonts/AdineueCHOP-Black.otf');
}
@font-face {
    font-family: adineue-chop-bold;
    src: url('../assets/fonts/AdineueCHOP-Bold.otf');
}
@font-face {
    font-family: adineue-chop-regular;
    src: url('../assets/fonts/AdineueCHOP-Regular.otf');
}
@font-face {
    font-family: adineue-chop-light;
    src: url('../assets/fonts/AdineueCHOP-Light.otf');
}
@font-face {
    font-family: adineue-chop-x-narrow-black;
    src: url('../assets/fonts/adineueCHOPXNarrow-Black.otf');
}
@font-face {
    font-family: "peregrin studio denton light";
    src: url('../assets/fonts/Peregrin-Studio-Denton-Light.otf');
}
@font-face {
    font-family: "itc franklin gothic std book compressed";
    src: url('../assets/fonts/ITC-Franklin-Gothic-Std-Book-Compressed.woff');
}
@font-face {
    font-family: "itc franklin gothic std demi compressed";
    src: url('../assets/fonts/ITC-Franklin-Gothic-Std-Demi-Compressed.woff');
}

/** adihaus-din  */
$base-font-family: 'adihaus-din', 'sans-serif';
$base-font-family-ad: 'adihaus-din', 'sans-serif';
$base-font-family-ad-medium: 'adihaus-din-medium', 'sans-serif';
$base-font-family-ad-bold: 'adihaus-din-bold', 'sans-serif';

/** adihaus-chop*/
$base-font-family-ac-black: 'adineue-chop-black', 'sans-serif';/** extra bold */
$base-font-family-ac-bold: 'adineue-chop-bold', 'sans-serif';/** bold */
$base-font-family-ac: 'adineue-chop-regular', 'sans-serif';
$base-font-family-ac-light: 'adineue-chop-light', 'sans-serif';
$base-font-family-ac-x-narrow-black: 'adineue-chop-x-narrow-black', 'sans-serif';
$base-line-height: 1.2;

/** other one  */
$bff-peregrin-denton-light: "peregrin studio denton light", 'sans-serif';
$bff-itc-fgs-book: "itc franklin gothic std book compressed", 'sans-serif';
$bff-itc-fgs-demi: "itc franklin gothic std demi compressed", 'sans-serif';