@import 'src/styles/variables';


.order-success-container {
  width: 100%;
  height: auto;
  background-color: $primary;

  .order-success-head {
    padding: 108px 100px 100px;

    .order-success-head-wrapper {
      width: 100%;
      max-width: 800px;

      .order-success-head-item-icon-title-wrapper {
        margin: 0 0 34px;
        display: grid;
        grid-template-columns: max-content 1fr;
        gap: 0 20px;

        .order-success-head-item-icon {
          width: 78px;
          height: 78px;
        }

        .order-success-head-title {
          margin: 16px 0 0;
          align-self: center;
          color: $on-primary;
          font-family: $bff-itc-fgs-demi;
          font-size: 70px;
          font-style: normal;
          font-weight: 600;
          line-height: 83%; /* 58.1px */
          letter-spacing: -0.7px;
          text-transform: uppercase;
        }
      }

      .order-success-head-description {
        color: $on-primary-light;
        font-family: $bff-peregrin-denton-light;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 120%; /* 19.2px */
        letter-spacing: 0.8px;
      }

      @include device (xs) {
        .order-success-head-item-icon-title-wrapper {
          gap: 0 10px;

          .order-success-head-item-icon {
            width: 33px;
            height: 33px;
          }

          .order-success-head-title {
            margin: 8px 0 0;
            font-size: 36px;
            letter-spacing: -0.36px;
          }
        }

        .order-success-head-description {
          font-size: 12px;
          line-height: normal;
          letter-spacing: 0.6px;
        }
      }

      @include device (s) {
        .order-success-head-item-icon-title-wrapper {
          .order-success-head-item-icon {
            width: 44px;
            height: 44px;
          }

          .order-success-head-title {
            margin: 12px 0 0;
            font-size: 44px;
            letter-spacing: -0.44px;
            line-height: 75%;
          }
        }

        .order-success-head-description {
          font-size: 14px;
          line-height: normal;
          letter-spacing: 0.6px;
        }
      }

      @include device (m) {
        .order-success-head-item-icon-title-wrapper {
          .order-success-head-item-icon {
            width: 55px;
            height: 55px;
          }

          .order-success-head-title {
            margin: 14px 0 0;
            font-size: 53px;
            letter-spacing: -0.53px;
            line-height: 77%;
          }
        }

        .order-success-head-description {
          font-size: 14px;
          line-height: normal;
          letter-spacing: 0.6px;
        }
      }

      @include device (l) {
        .order-success-head-item-icon-title-wrapper {
          .order-success-head-item-icon {
            width: 66px;
            height: 66px;
          }

          .order-success-head-title {
            margin: 15px 0 0;
            font-size: 61px;
            letter-spacing: -0.61px;
          }
        }
      }
    }
  }

  .order-success-detail-container {
    position: relative;
    padding: 108px 100px;
    @include dashedBorder(t);

    .hashtag-merky-fc-logo-with-ball {
      height: 370px;
      width: 700px;
      position: absolute;
      top: -174px;
      right: 0;
      background-image: url('../../assets/images/ball-logo-still.png');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;

      @include device(xs, s) {
        display: none;
      }
      @include device(m) {
        width: 500px;
      }
      @include device(l) {
        width: 600px;
      }
    }

    .order-success-detail-wrap {
      width: 100%;
      max-width: 800px;

      .order-success-order-details {
        margin: 0 0 100px;

        .order-success-order-title {
          margin: 6px 0 40px;
          color: $on-primary-light;
          font-family: $bff-itc-fgs-demi;
          font-size: 45px;
          font-style: normal;
          font-weight: 600;
          line-height: 83%; /* 37.35px */
          letter-spacing: -0.45px;
          text-transform: uppercase;
        }

        .order-success-items-wrap {
          margin: 0 0 15px;
          display: grid;
          gap: 14px 0;

          .order-success-item-wrap {
            // padding: 9px 10px;
            padding: 15px 20px 15px 15px;
            width: 100%;
            max-width: 770px;
            height: auto;
            display: grid;
            grid-template-columns: max-content 1fr max-content;
            gap: 0 10px;
            color: $on-secondary-dark;
            background-color: $secondary;
            border: 1px dashed #B3B4B480;

            .order-success-item-img {
              width: 104px;
              aspect-ratio: 1/1;
            }

            .order-success-item-details {
              .order-success-item {
                color: $on-secondary-dark;
                font-family: $bff-itc-fgs-book;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal; /* 14.94px */
                letter-spacing: 1.8px;
                text-transform: uppercase;
              }
            }

            .order-success-item-price {
              // margin: 0 0 -6px;
              align-self: end;
              color: $on-secondary;
              font-family: $bff-itc-fgs-book;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: normal; /* 14.94px */
              letter-spacing: 1.8px;
            }
          }
        }

        .order-success-discount-wrap {
          margin: 0 0 14px;
          padding: 80px 20px 16px;
          width: 100%;
          max-width: 770px;
          height: max-content;
          display: flex;
          justify-content: space-between;
          background-color: $secondary;
          border: 2px solid #363636;

          .order-success-discount-label {
            color: $on-secondary-dark;
            font-family: $bff-itc-fgs-book;
            font-size: 36px;
            font-style: normal;
            font-weight: 600;
            line-height: 83%; /* 29.88px */
            letter-spacing: -0.36px;
            text-transform: uppercase;
          }
        }

        .order-success-detail-total-wrap {
          padding: 80px 20px 16px;
          width: 100%;
          max-width: 770px;
          height: max-content;
          display: flex;
          justify-content: space-between;
          background-color: $tertiary;

          .order-success-detail-label {
            color: $on-tertiary;
            font-family: $bff-itc-fgs-demi;
            font-size: 45px;
            font-style: normal;
            font-weight: 600;
            line-height: 83%; /* 37.35px */
            letter-spacing: -0.45px;
            text-transform: uppercase;
          }
        }


        @include device(xs) {
          margin: 0 0 60px;

          .order-success-order-title {
            font-size: 36px;
            letter-spacing: -0.36px;
          }

          .order-success-items-wrap {
            .order-success-item-wrap {
              .order-success-item-img {
                width: 63px;
              }

              .order-success-item-details {
                .order-success-item {
                  font-size: 15px;
                  letter-spacing: 1.5px;
                }
              }
              
              .order-success-item-price {
                font-size: 15px;
                letter-spacing: 1.5px;
              }
            }
          }

          .order-success-discount-wrap {
            padding: 40px 10px 10px;
  
            .order-success-discount-label {
              font-size: 25px;
              letter-spacing: -0.25px;
            }
          }

          .order-success-detail-total-wrap {
            padding: 40px 10px 10px;

            .order-success-detail-label {
              font-size: 36px;
              letter-spacing: -0.36px;
            }
          }
        }

        @include device(s) {
          margin: 0 0 70px;

          .order-success-order-title {
            font-size: 38px;
            letter-spacing: -0.38px;
          }
          
          .order-success-items-wrap {
            .order-success-item-wrap {
              .order-success-item-img {
                width: 73px;
              }

              .order-success-item-details {
                .order-success-item {
                  font-size: 15px;
                  letter-spacing: 1.5px;
                }
              }

              .order-success-item-price {
                font-size: 15px;
                letter-spacing: 1.5px;
              }
            }
          }

          .order-success-discount-wrap {
            padding: 50px 13px 12px;
  
            .order-success-discount-label {
              font-size: 28px;
              letter-spacing: -0.28px;
            }
          }

          .order-success-detail-total-wrap {
            padding: 50px 13px 12px;

            .order-success-detail-label {
              font-size: 38px;
              letter-spacing: -0.38px;
            }
          }
        }

        @include device(m) {
          margin: 0 0 80px;

          .order-success-order-title {
            font-size: 40px;
            letter-spacing: -0.40px;
          }

          .order-success-items-wrap {
            .order-success-item-wrap {
              .order-success-item-img {
                width: 83px;
              }

              .order-success-item-details {
                .order-success-item {
                  font-size: 16px;
                  letter-spacing: 1.6px;
                }
              }

              .order-success-item-price {
                font-size: 16px;
                letter-spacing: 1.6px;
              }
            }
          }

          .order-success-discount-wrap {
            padding: 60px 15px 14px;
  
            .order-success-discount-label {
              font-size: 30px;
              letter-spacing: -0.30px;
            }
          }

          .order-success-detail-total-wrap {
            padding: 60px 15px 14px;

            .order-success-detail-label {
              font-size: 40px;
              letter-spacing: -0.40px;
            }
          }
        }

        @include device(l) {
          margin: 0 0 90px;

          .order-success-order-title {
            font-size: 42px;
            letter-spacing: -0.42px;
          }

          .order-success-items-wrap {
            .order-success-item-wrap {
              .order-success-item-img {
                width: 93px;
              }

              .order-success-item-details {
                .order-success-item {
                  font-size: 17px;
                  letter-spacing: 1.7px;
                }
              }

              .order-success-item-price {
                font-size: 17px;
                letter-spacing: 1.7px;
              }
            }
          }

          .order-success-discount-wrap {
            padding: 70px 17px 16px;
  
            .order-success-discount-label {
              font-size: 33px;
              letter-spacing: -0.33px;
            }
          }

          .order-success-detail-total-wrap {
            padding: 70px 17px 16px;

            .order-success-detail-label {
              font-size: 42px;
              letter-spacing: -0.42px;
            }
          }
        }
      }
    }

    .order-success-help-wrapper {
      display: grid;
      grid-template-columns: max-content 1fr;
      gap: 0 15px;

      .order-success-help-icon {
        height: 47px;
        width: 47px;
      }

      .order-success-help-link {
        margin: 8px 0 0;
        align-self: center;
        color: $on-primary-light;
        font-family: $bff-itc-fgs-book;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 83%; /* 14.94px */
        letter-spacing: 1.8px;
        text-transform: uppercase;

        .manage-membership-link {
          color: $link;
          text-decoration: underline;
          cursor: pointer;
        }
      }

      @include device (xs) {
        gap: 0 10px;

        .order-success-help-icon {
          height: 32px;
          width: 32px;
        }

        .order-success-help-link {
          font-size: 15px;
          letter-spacing: 1.5px;
        }
      }

      @include device (s) {
        .order-success-help-icon {
          height: 35px;
          width: 35px;
        }

        .order-success-help-link {
          font-size: 16px;
          letter-spacing: 1.6px;
        }
      }

      @include device (m) {
        .order-success-help-icon {
          height: 39px;
          width: 39px;
        }

        .order-success-help-link {
          font-size: 16px;
          letter-spacing: 1.6px;
        }
      }

      @include device (l) {
        .order-success-help-icon {
          height: 42px;
          width: 42px;
        }

        .order-success-help-link {
          font-size: 17px;
          letter-spacing: 1.7px;
        }
      }
    }
  }



  @include device(xs) {
    .order-success-head {
      padding: 40px 20px 50px;
    }

    .order-success-detail-container {
      padding: 50px 20px;
    }
  }

  @include device(s) {
    .order-success-head {
      padding: 57px 40px 62px;
    }

    .order-success-detail-container {
      padding: 64px 40px;
    }
  }

  @include device(m) {
    .order-success-head {
      padding: 74px 60px 75px;
    }

    .order-success-detail-container {
      padding: 79px 60px;
    }
  }

  @include device(l) {
    .order-success-head {
      padding: 91px 80px 87px;
    }

    .order-success-detail-container {
      padding: 93px 80px;
    }
  }
}