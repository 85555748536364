@import 'src/styles/_variables.scss';

.checkout-container {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    background-color: $primary;

    .checkout-wrapper {
        padding: 100px 100px;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: max-content 1fr;
        gap: 0 100px;

        .checkout-items-container {
            width: 100%;
            height: 100%;

            .checkout-items-wrap {
                width: 315px;
                height: max-content;
                background-color: $secondary;

                .booking-basket-title {
                    padding: 10px 15px 4px;
                    background-color: $primary;
                    color: $on-primary;
                    font-family: $bff-itc-fgs-demi;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 1; /* 14.94px */
                    letter-spacing: 1.8px;
                    text-transform: uppercase;
                    border: 2px solid $border-light-black;
                }

                .booking-basket-items {
                    max-height: 60vh;
                    overflow: hidden auto;
                    border-left: 2px solid $border-light-black;
                    border-right: 2px solid $border-light-black;

                    /* width */
                    &::-webkit-scrollbar {
                        width: 8px;
                    }

                    /* Track */
                    &::-webkit-scrollbar-track {
                        background-color: transparent;
                    }

                    /* Handle */
                    &::-webkit-scrollbar-thumb {
                        background: #A2A4A4;
                        border-radius: 3px;
                    }

                    /* Handle on hover */
                    &::-webkit-scrollbar-thumb:hover {
                        background: #B1B2B2;
                    }

                    .booking-basket-item {
                        padding: 18px 15px 14px;
                        border-bottom: 2px solid $border-light-black;

                        .booking-basket-item-detail {
                            padding: 0 0 1px;
                            color: $on-secondary;
                            font-family: $bff-itc-fgs-book;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal; /* 14.94px */
                            letter-spacing: 1.8px;
                            text-transform: uppercase;
                        }

                        .booking-basket-item-action-wrapper {
                            padding: 20px 0 0;
                            display: flex;
                            justify-content: space-between;

                            .booking-basket-item-price {
                                color: $on-secondary;
                                font-family: $bff-itc-fgs-demi;
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 83%; /* 14.94px */
                                letter-spacing: 1.8px;
                                text-transform: uppercase;
                            }

                            .booking-basket-item-remove {
                                color: $on-secondary;
                                font-family: $bff-itc-fgs-demi;
                                font-size: 13px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 83%; /* 10.79px */
                                letter-spacing: 1.3px;
                                text-transform: uppercase;
                                text-decoration-line: underline;
                                cursor: pointer;
                                &:hover {
                                    color: $merky-fc-yellow;
                                }
                            }
                        }

                        // .booking-basket-empty-box {
                        //     width: 100%;
                        //     height: 50px;
                        //     display: flex;
                        //     justify-content: center;
                        //     align-items: center;

                        //     .booking-basket-empty-box-msg {
                        //         font-family: $base-font-family-ad;
                        //         color: $on-secondary-dark;
                        //         font-size: 16px;
                        //         font-style: normal;
                        //         font-weight: 400;
                        //         line-height: normal;
                        //         letter-spacing: 0.6px;
                        //         text-transform: capitalize;
                        //     }
                        // }
                    }

                    &:has(+.booking-basket-discount-wrap) .booking-basket-item:last-child {
                        border-bottom: 0;
                    }
                }

                .booking-basket-discount-wrap {
                    padding: 18px 15px 10px;
                    display: flex;
                    justify-content: space-between;
                    align-items: baseline;
                    border: 2px solid #363636;
                    // border-top-width: 0;
                    color: $on-secondary-dark;

                    &.bb-0 {
                        border-bottom: 0;
                    }

                    .booking-basket-discount-label {
                        font-family: $bff-itc-fgs-book;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: 1.2px;
                        text-transform: uppercase;

                        &.price {
                            font-size: 32px;
                            letter-spacing: -0.35px;
                        }
                    }
                }

                .booking-basket-total-wrap {
                    padding: 20px 15px 8px;
                    width: 100%;
                    height: fit-content;
                    display: flex;
                    justify-content: space-between;
                    align-items: baseline;
                    background-color: $tertiary;

                    .booking-basket-total-title {
                        color: $on-tertiary;
                        font-family: $bff-itc-fgs-demi;
                        font-size: 30px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 1; /* 14.94px */
                        letter-spacing: 0.10px;
                        text-transform: uppercase;
                    }

                    .booking-basket-total-price {
                        color: $on-tertiary;
                        font-family: $bff-itc-fgs-demi;
                        font-size: 45px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 1; /* 37.35px */
                        letter-spacing: -0.45px;
                    }
                }
            }
        }

        .checkout-payment-intent-wrap {
            // @include dashedBorder(all);
            // min-height: 400px;

            form {
                margin: 0 auto;
                padding: 0;
                width: 100%;
                max-width: 600px;
                // box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
                //     0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);


                .checkout-payment-tcs-container {
                    padding: 55px 0;
                    @include dashedBorder(t, b);

                    .checkout-payment-tcs-wrapper {
                        display: grid;
                        grid-template-columns: min-content 1fr;
                        gap: 0 10px;

                        .checkout-payment-tcs-checkbox-wrapper {
                            width: 34px;
                            height: 34px;
                            cursor: pointer;

                            .checkout-payment-tcs-checkbox-input {
                                display: none;
                            }

                            .checkout-payment-tcs-checkbox-icon {
                                width: 100%;
                                height: 100%;
                                cursor: pointer;
                            }
                        }

                        .checkout-payment-tcs-label {
                            margin: 6px 0 0;
                            align-self: center;
                            color: $on-primary-light;
                            font-family: $bff-itc-fgs-book;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 83%; /* 14.94px */
                            letter-spacing: 1.8px;
                            text-transform: uppercase;
                            user-select: none;
                            -webkit-user-select: none;

                            .checkout-payment-tcs-link {
                                text-decoration: underline;
                                color: $link;
                            }
                        }
                    }

                    .checkout-payment-err-msg {
                        margin: 8px 0 0 0px;
                        color: $error;
                        opacity: 0.7;
                        font-family: $bff-peregrin-denton-light;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: 0.24px;
                    }
                }
            }

            .hidden {
                display: none;
            }

            #payment-message {
                color: $error;
                font-size: 16px;
                line-height: 20px;
                padding-top: 20px;
                text-align: center;
            }

            #payment-element {
                margin-bottom: 50px;
            }

            /* Buttons and links */
            .pay-button {
                margin: 55px 0 0;
                padding: 15px 16px 9px;
                width: 100%;
                background: $secondary;
                color: $on-secondary-dark;
                font-family: $bff-itc-fgs-book;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 83%;
                letter-spacing: 1.8px;
                text-transform: uppercase;
                transition: all 0.2s ease;
                border-radius: 16px;
                border: 3px solid $border-light-black;
                box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
                cursor: pointer;

                &:focus-visible {
                    outline: 0px;
                    border-width: 1px;
                    border-color: hsla(69, 100%, 50%, 50%);
                    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0 0 0 3px hsla(69, 100%, 50%, 25%), 0 1px 1px 0 rgba(255, 255, 255, 0.12);
                }
            }

            button:hover {
                filter: contrast(115%);
            }

            button:disabled {
                opacity: 0.5;
                cursor: default;
            }

            /* spinner/processing state, errors */
            .spinner,
            .spinner:before,
            .spinner:after {
                border-radius: 50%;
            }

            .spinner {
                color: #ffffff;
                font-size: 22px;
                text-indent: -99999px;
                margin: 0px auto;
                position: relative;
                width: 20px;
                height: 20px;
                box-shadow: inset 0 0 0 2px;
                -webkit-transform: translateZ(0);
                -ms-transform: translateZ(0);
                transform: translateZ(0);
            }

            .spinner:before,
            .spinner:after {
                position: absolute;
                content: "";
            }

            .spinner:before {
                width: 10.4px;
                height: 20.4px;
                background: $secondary;
                border-radius: 20.4px 0 0 20.4px;
                top: -0.2px;
                left: -0.2px;
                -webkit-transform-origin: 10.4px 10.2px;
                transform-origin: 10.4px 10.2px;
                -webkit-animation: loading 2s infinite ease 1.5s;
                animation: loading 2s infinite ease 1.5s;
            }

            .spinner:after {
                width: 10.4px;
                height: 10.2px;
                background: $secondary;
                border-radius: 0 10.2px 10.2px 0;
                top: -0.1px;
                left: 10.2px;
                -webkit-transform-origin: 0px 10.2px;
                transform-origin: 0px 10.2px;
                -webkit-animation: loading 2s infinite ease;
                animation: loading 2s infinite ease;
            }

            @-webkit-keyframes loading {
                0% {
                    -webkit-transform: rotate(0deg);
                    transform: rotate(0deg);
                }

                100% {
                    -webkit-transform: rotate(360deg);
                    transform: rotate(360deg);
                }
            }

            @keyframes loading {
                0% {
                    -webkit-transform: rotate(0deg);
                    transform: rotate(0deg);
                }

                100% {
                    -webkit-transform: rotate(360deg);
                    transform: rotate(360deg);
                }
            }


            // .lds-ring {
            //     display: inline-block;
            //     position: relative;
            //     width: 30px;
            //     height: 30px;
            // }

            // .lds-ring div {
            //     box-sizing: border-box;
            //     display: block;
            //     position: absolute;
            //     width: 26px;
            //     height: 26px;
            //     margin: 2px;
            //     border: 3px solid #fff;
            //     border-radius: 50%;
            //     animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            //     border-color: #fff transparent transparent transparent;
            // }

            // .lds-ring div:nth-child(1) {
            //     animation-delay: -0.45s;
            // }

            // .lds-ring div:nth-child(2) {
            //     animation-delay: -0.3s;
            // }

            // .lds-ring div:nth-child(3) {
            //     animation-delay: -0.15s;
            // }

            // @keyframes lds-ring {
            //     0% {
            //         transform: rotate(0deg);
            //         -webkit-transform: rotate(0deg);
            //     }

            //     100% {
            //         transform: rotate(360deg);
            //         -webkit-transform: rotate(360deg);
            //     }
            // }

        }
    }



    @include device(xs) {
        .checkout-wrapper {
            padding: 40px 20px 60px;
            grid-template-columns: 1fr;
            gap: 100px 0;

            .checkout-items-container {
                .checkout-items-wrap {
                    margin: 0 auto;
                    width: auto;
                    max-width: 400px;

                    .booking-basket-title {
                        padding: 11px 15px 5px;
                        font-size: 16px;
                        letter-spacing: 0.16px;
                    }
    
                    .booking-basket-items .booking-basket-item {
                        .booking-basket-item-detail {
                            font-size: 15px;
                            letter-spacing: 1.5px;
                        }

                        .booking-basket-item-action-wrapper .booking-basket-item-price {
                            font-size: 16px;
                            letter-spacing: 0.16px;
                        }
                    }
    
                    .booking-basket-discount-wrap .booking-basket-discount-label {
                        font-size: 18px;
                        letter-spacing: 1px;
                        &.price {
                            font-size: 26px;
                            letter-spacing: -0.26px;
                        }
                    }
    
                    .booking-basket-total-wrap {
                        .booking-basket-total-title {
                            font-size: 24px;
                            letter-spacing: 2.4px;
                        }
                        .booking-basket-total-price {
                            font-size: 36px;
                            letter-spacing: -0.36px;
                        }
                    }
                }
            }

            .checkout-payment-intent-wrap {
                form {
                    padding: 30px 0px;

                    .checkout-payment-tcs-container .checkout-payment-tcs-wrapper .checkout-payment-tcs-checkbox-wrapper .checkout-payment-tcs-label {
                        font-size: 15px;
                        letter-spacing: 1.5px;
                        line-height: normal;
                    }
                }

                button {
                    font-size: 15px;
                    letter-spacing: 1.5px;
                }
            }
        }
    }

    @include device(s) {
        .checkout-wrapper {
            padding: 55px 40px 70px;
            grid-template-columns: 1fr;
            gap: 100px 0;

            .checkout-items-container {
                .checkout-items-wrap {
                    margin: 0 auto;
                    width: auto;
                    max-width: 400px;
                }
            }
        }
    }

    @include device(m) {
        .checkout-wrapper {
            padding: 70px 60px 80px;
            grid-template-columns: 1fr;
            gap: 100px 0;

            .checkout-items-container {
                .checkout-items-wrap {
                    margin: 0 auto;
                    width: auto;
                    max-width: 400px;
                }
            }
        }
    }

    @include device(l) {
        .checkout-wrapper {
            padding: 85px 80px 90px;
            gap: 0 60px;
        }
    }
}