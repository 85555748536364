@import 'src/styles/_variables.scss';

.account-sign-in-container {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    background-color: $primary;

    .account-sign-in-wrapper {
        margin: 0 auto;
        min-width: 320px;
        width: 100%;
        max-width: 470px;
        height: 100%;


        .account-sign-in-form {
            padding: 80px 20px 50px;
            @include dashedBorder(b);

            .account-sign-in-form-header {
                margin: 0 0 50px;

                .account-sign-in-form-header-title {
                    margin: 0 0 20px;
                    color: $on-primary-light;
                    font-family: $bff-itc-fgs-demi;
                    font-size: 45px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 1;
                    /* 83%; 37.35px */
                    letter-spacing: -0.45px;
                    text-transform: uppercase;
                }

                .account-sign-in-form-header-info {
                    color: $on-primary-light;
                    font-family: $bff-peregrin-denton-light;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 1; /* 19.2px */
                    letter-spacing: 0.8px;
                }
            }

            .account-sign-in-form-not-registered {
                margin: 16px 0 -10px;
                color: $error;
                font-family: $bff-peregrin-denton-light;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.24px;
            }

            .account-sign-in-form-body {
                display: grid;
                gap: 16px 0;

                .account-sign-in-form-body-item {
                    position: relative;
                    width: 100%;
                    height: 40px;

                    .account-sign-in-form-body-item-input {
                        padding: 12px 20px 8px;
                        width: 100%;
                        height: 100%;
                        border-radius: 12px;
                        border: 3px solid $border-light-black;
                        outline: 0;
                        background: $secondary;
                        color: $on-secondary;
                        font-family: $bff-itc-fgs-book;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 1;
                        /* 14.94px */
                        letter-spacing: 1.8px;
                        &::placeholder, &::-webkit-input-placeholder {
                            text-transform: uppercase;
                        }

                        &[type="password"]:not(:placeholder-shown) {
                            letter-spacing: 5px;
                        }
                    }
                }

                .account-sign-in-form-body-item-err-msg {
                    margin: 6px 0 0 8px;
                    color: $error;
                    opacity: 0.7;
                    font-family: $bff-peregrin-denton-light;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 1;
                    letter-spacing: 0.24px;
                }
            }

            .account-forgotten-link-wrapper {
                margin: 30px 0 -4px;

                .account-forgotten-link-text {
                    color: $on-primary-light;
                    font-family: $bff-itc-fgs-book;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 1.8px;
                    text-transform: uppercase;

                    .account-forgotten-link {
                        color: $link;
                        text-decoration-line: underline;
                        cursor: pointer;
                    }
                }
            }
        }

        .account-sign-in-action-wrapper {
            padding: 55px 20px;
            user-select: none;
            -webkit-user-select: none;

            .account-sign-in-action-btn {
                padding: 14px 26px;
                width: 100%;
                height: 45px;
                font-family: $bff-itc-fgs-book;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 1;
                letter-spacing: 1.8px;
                text-transform: uppercase;
                background: $secondary;
                color: $on-secondary-dark;
                border-radius: 16px;
                outline: 0;
                border: 3px solid $border-light-black;
                cursor: pointer;
            }
        }

        @include device(xs, s, m) {
            .account-sign-in-form {
                .account-sign-in-form-header {
                    margin: 0 0 50px;

                    .account-sign-in-form-header-title {
                        font-size: 35px;
                        letter-spacing: -0.35px;
                    }

                    .account-sign-in-form-header-info {
                        font-size: 12px;
                        line-height: normal;
                        letter-spacing: 0.6px;
                    }
                }

                .account-sign-in-form-body {
                    .account-sign-in-form-body-item {
                        .account-sign-in-form-body-item-input {
                            font-size: 16px;
                            letter-spacing: 0.32px;
                        }
                    }
                }
            }
        }
    }
}