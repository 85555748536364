@import 'src/styles/variables';


.new-membership-container {
  width: 100%;
  height: auto;
  background-color: $primary;

  .membership-wrap {
    padding: 100px;
    display: flex;
    flex-flow: row nowrap;
    gap: 50px 26px;

    .details-container {
      flex: 1;
      width: 100%;

      .details-wrap {
        padding-top: 4px;
        max-width: 450px;

        &:not(:last-child) {
          margin-bottom: 40px;
        }

        .detail-title {
          margin: 0 0 16px;
          font-family: $bff-itc-fgs-demi;
          font-size: 45px;
          font-style: normal;
          font-weight: 600;
          line-height: 83%;
          /* 37.35px */
          letter-spacing: -0.45px;
          text-transform: uppercase;
        }

        .points-wrap {
          list-style-type: disc;
          margin-inline-start: 24px;

          &.style-none {
            list-style-type: none;
            margin-inline-start: unset;
          }

          .point {
            font-family: $bff-peregrin-denton-light;
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            letter-spacing: 0.6px;
          }
        }
      }
    }

    .form-container {
      flex: 1;
      width: 100%;

      .form-title {
        margin: 0 0 20px;
        padding-left: 10px;
        color: $on-primary;
        font-family: $bff-itc-fgs-demi;
        font-size: 45px;
        font-style: normal;
        font-weight: 600;
        line-height: 1;
        /* 83%; 37.35px */
        letter-spacing: -0.45px;
        text-transform: uppercase;
      }

      .fields-wrap {
        margin: 0 0 30px;
        padding-left: 10px;
        display: grid;
        gap: 16px 0;

        .input-field-wrap {
          width: 100%;
          max-width: 450px;
          height: 40px;

          .input-field {
            padding: 12px 16px 8px;
            width: 100%;
            height: 100%;
            border-radius: 12px;
            border: 3px solid $border-light-black;
            outline: 0;
            background: $secondary;
            color: $on-secondary;
            font-family: $bff-itc-fgs-book;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 1;
            /* 83%; 14.94px */
            letter-spacing: 1.8px;
            text-transform: uppercase;

            &::placeholder,
            &::-webkit-input-placeholder {
              text-transform: uppercase;
            }

            &.date {
              &::-webkit-calendar-picker-indicator {
                filter: invert(.4);
              }

              &.placeholder {
                position: relative;
                color: #757575;

                &::after {
                  content: '(Date of Birth)';
                  display: inline;
                  position: absolute;
                  left: 125px;
                  top: 10px;
                }
              }
            }

            &.select-input {
              padding: 10px 16px 10px 12px;
              -moz-appearance: none;
              -webkit-appearance: none;
              appearance: none;
              background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' height='10px' width='14px'%3E%3Ctext x='0' y='10' fill='gray'%3E%E2%96%BE%3C/text%3E%3C/svg%3E");
              background-repeat: no-repeat;
              background-size: 22px 15px;
              background-position: right 12px center;
              background-clip: border-box;
              -moz-background-clip: border-box;
              -webkit-background-clip: border-box;
              // https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browsers
              &::-webkit-calendar-picker-indicator {
                filter: invert(.4);
              }

              &.placeholder {
                padding: 11px 14px 10px;
                color: #757575;
              }
            }
          }
        }
      }

      .input-field-err-msg {
        margin: 8px 0 0 8px;
        color: $error;
        opacity: 0.7;
        font-family: $bff-peregrin-denton-light;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 1;
        letter-spacing: 0.24px;
      }

      .input-field-note {
        padding: 0 10px;
        margin-bottom: 40px;
        color: $on-primary-light;
        font-family: $bff-itc-fgs-book;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 1;
        /* 14.94px */
        letter-spacing: 1.8px;
        text-transform: uppercase;
      }

      .tcs-container {
        padding: 30px 10px;
        @include dashedBorder(t, b);

        .tcs-wrap {
          display: grid;
          grid-template-columns: min-content 1fr;
          gap: 0 10px;

          .tcs-checkbox-wrap {
            width: 34px;
            height: 34px;
            cursor: pointer;

            .tcs-checkbox-input {
              display: none;
            }

            .tcs-checkbox-icon {
              width: 100%;
              height: 100%;
              cursor: pointer;
            }
          }

          .tcs-label {
            margin-bottom: -6px;
            align-self: center;
            color: $on-primary-light;
            font-family: $bff-itc-fgs-book;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 1;
            letter-spacing: 1.8px;
            text-transform: uppercase;
            user-select: none;
            -webkit-user-select: none;

            .tcs-link {
              text-decoration-line: underline;
              color: $link;
            }
          }
        }
      }

      .re-captcha-wrap {
        padding: 30px 10px 0;
      }

      .action-wrapper {
        padding: 45px 10px;
        user-select: none;
        -webkit-user-select: none;

        .action-btn-wrap {
          width: 100%;
          max-width: 450px;
          height: 45px;

          .action-btn {
            padding: 14px 26px;
            width: 100%;
            height: 100%;
            outline: 0;
            border-radius: 16px;
            border: 3px solid $border-light-black;
            font-family: $bff-itc-fgs-book;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 1;
            letter-spacing: 1.8px;
            text-transform: uppercase;
            color: $on-secondary-dark;
            background: $secondary;
            cursor: pointer;

            &.in-valid {
              opacity: 0.6;
              cursor: not-allowed;
            }
          }
        }
      }

      @include device(xs, s, m) {
        .form-title {
          font-size: 35px;
          letter-spacing: -0.35px;
        }

        .fields-wrap {
          .input-field-wrap {
            .input-field {
              font-size: 16px;
              letter-spacing: 0.32px;

              &.date {
                &::-webkit-calendar-picker-indicator {
                  filter: invert(.4);
                }
                &.placeholder {
                  position: relative;
                  color: #757575;
                  &::after {
                    content: '(Date of Birth)';
                    display: inline;
                    position: absolute;
                    left: 100px;
                    top: 10px;
                  }
                }
              }
            }
          }
        }

        .input-field-note {
          font-size: 16px;
          letter-spacing: 0.32px;
        }
      }
    }

    .checkout-payment-intent-wrap {
      flex: 1.2;
      width: 100%;

      form {
        margin: 0 auto;
        padding: 0;
        width: 100%;
        max-width: 600px;

        .form-title {
          margin: 0 0 20px;
          color: $on-primary;
          font-family: $bff-itc-fgs-demi;
          font-size: 45px;
          font-style: normal;
          font-weight: 600;
          line-height: 1;
          /* 83%; 37.35px */
          letter-spacing: -0.45px;
          text-transform: uppercase;

          @include device(xs, s, m) {
            font-size: 35px;
            letter-spacing: -0.35px;
          }
        }

        .checkout-payment-tcs-container {
          padding: 55px 0;
          @include dashedBorder(t, b);

          .checkout-payment-tcs-wrapper {
            display: grid;
            grid-template-columns: min-content 1fr;
            gap: 0 10px;

            .checkout-payment-tcs-checkbox-wrapper {
              width: 34px;
              height: 34px;
              cursor: pointer;

              .checkout-payment-tcs-checkbox-input {
                display: none;
              }

              .checkout-payment-tcs-checkbox-icon {
                width: 100%;
                height: 100%;
                cursor: pointer;
              }
            }

            .checkout-payment-tcs-label {
              margin: 6px 0 0;
              align-self: center;
              color: $on-primary-light;
              font-family: $bff-itc-fgs-book;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: 83%;
              /* 14.94px */
              letter-spacing: 1.8px;
              text-transform: uppercase;
              user-select: none;
              -webkit-user-select: none;

              .checkout-payment-tcs-link {
                text-decoration: underline;
                color: $link;
              }
            }
          }

          .checkout-payment-err-msg {
            margin: 8px 0 0 0px;
            color: $error;
            opacity: 0.7;
            font-family: $bff-peregrin-denton-light;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.24px;
          }
        }
      }

      .hidden {
        display: none;
      }

      #payment-message {
        color: $error;
        font-size: 16px;
        line-height: 20px;
        padding-top: 20px;
        text-align: center;
      }

      #payment-element {
        margin-bottom: 50px;
      }

      /* Buttons and links */
      button {
        margin: 55px 0 0;
        padding: 15px 16px 9px;
        width: 100%;
        background: $secondary;
        color: $on-secondary-dark;
        font-family: $bff-itc-fgs-book;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 83%;
        letter-spacing: 1.8px;
        text-transform: uppercase;
        transition: all 0.2s ease;
        border-radius: 16px;
        border: 3px solid $border-light-black;
        box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
        cursor: pointer;

        &:focus-visible {
          outline: 0px;
          border-width: 1px;
          border-color: hsla(69, 100%, 50%, 50%);
          box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0 0 0 3px hsla(69, 100%, 50%, 25%), 0 1px 1px 0 rgba(255, 255, 255, 0.12);
        }
      }

      button:hover {
        filter: contrast(115%);
      }

      button:disabled {
        opacity: 0.5;
        cursor: default;
      }

      /* spinner/processing state, errors */
      .spinner,
      .spinner:before,
      .spinner:after {
        border-radius: 50%;
      }

      .spinner {
        color: #ffffff;
        font-size: 22px;
        text-indent: -99999px;
        margin: 0px auto;
        position: relative;
        width: 20px;
        height: 20px;
        box-shadow: inset 0 0 0 2px;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
      }

      .spinner:before,
      .spinner:after {
        position: absolute;
        content: "";
      }

      .spinner:before {
        width: 10.4px;
        height: 20.4px;
        background: $secondary;
        border-radius: 20.4px 0 0 20.4px;
        top: -0.2px;
        left: -0.2px;
        -webkit-transform-origin: 10.4px 10.2px;
        transform-origin: 10.4px 10.2px;
        -webkit-animation: loading 2s infinite ease 1.5s;
        animation: loading 2s infinite ease 1.5s;
      }

      .spinner:after {
        width: 10.4px;
        height: 10.2px;
        background: $secondary;
        border-radius: 0 10.2px 10.2px 0;
        top: -0.1px;
        left: 10.2px;
        -webkit-transform-origin: 0px 10.2px;
        transform-origin: 0px 10.2px;
        -webkit-animation: loading 2s infinite ease;
        animation: loading 2s infinite ease;
      }

      @-webkit-keyframes loading {
        0% {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
        }

        100% {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }

      @keyframes loading {
        0% {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
        }

        100% {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }

    }



    @include device(xs) {
      padding: 20px;
      flex-flow: column nowrap;

      .details-container {
        .details-wrap {
          .detail-title {
            font-size: 36px;
            letter-spacing: -0.36px;
          }

          .points-wrap {
            margin-inline-start: 16px;

            .point {
              font-size: 12px;
            }
          }
        }
      }

      .checkout-payment-intent-wrap {
        form {
          padding: 30px 0px;

          .checkout-payment-tcs-container .checkout-payment-tcs-wrapper .checkout-payment-tcs-checkbox-wrapper .checkout-payment-tcs-label {
            font-size: 15px;
            letter-spacing: 1.5px;
            line-height: normal;
          }
        }

        button {
          font-size: 15px;
          letter-spacing: 1.5px;
        }
      }
    }

    @include device(s) {
      padding: 40px;
      flex-flow: column nowrap;

      .details-container {
        align-self: center;
        max-width: 400px;

        .details-wrap {
          .detail-title {
            font-size: 38px;
            letter-spacing: -0.38px;
          }

          .points-wrap {
            margin-inline-start: 18px;

            .point {
              font-size: 14px;
            }
          }
        }
      }

      .form-container {
        max-width: 400px;
        align-self: center;
      }

      .checkout-payment-intent-wrap {
        max-width: 400px;
        align-self: center;
      }
    }

    @include device(m) {
      padding: 60px;

      .details-container {
        .details-wrap {
          .detail-title {
            font-size: 40px;
            letter-spacing: -0.40px;
          }

          .points-wrap {
            margin-inline-start: 20px;

            .point {
              font-size: 12px;
            }
          }
        }
      }
    }

    @include device(l) {
      padding: 80px;

      .details-container {
        .details-wrap {
          max-width: 420px;

          .detail-title {
            font-size: 38px;
            letter-spacing: -0.38px;
          }

          .points-wrap {
            margin-inline-start: 16px;

            .point {
              font-size: 12px;
            }
          }
        }
      }

      .form-container {
        .fields-wrap {
          .input-field-wrap {
            max-width: 420px;
          }
        }

        .action-wrapper {
          .action-btn-wrap {
            max-width: 420px;
          }
        }
      }
    }
  }
}