@import 'src/styles/_variables.scss';

.account-container {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;

    .account-wrapper {
        padding: 100px 100px;
        display: grid;
        grid-template-columns: minmax(280px, 1fr) 2fr;
        gap: 50px 0;
        background-color: $primary;

        .account-my-details-membership-wrapper {
            .account-my-details-membership {
                &:first-child {
                    margin: 0 0 50px;
                }

                .account-my-details-membership-title {
                    margin: 0 0 26px;
                    color: $on-primary-light;
                    font-family: $bff-itc-fgs-demi;
                    font-size: 36px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 1; /* 37.35px */
                    letter-spacing: -0.36px;
                    text-transform: uppercase;
                }

                .account-my-details-membership-item {
                    margin: 0 0 14px;
                    color: $on-primary-light;
                    font-family: $bff-peregrin-denton-light;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                    letter-spacing: 0.6px;
                    text-transform: capitalize;
                    
                    &:last-child {
                        margin: 0 0 0;
                    }

                    &.email {
                        text-transform: unset;
                    }
                    
                    &.update-link,
                    .update-link {
                        display: block;
                        width: fit-content;
                        color: $link;
                        text-decoration-line: underline;
                        text-transform: uppercase;
                        cursor: pointer;
                    }
                }
            }

            @include device(s, m, l) {
                display: grid;
                grid-template-columns: repeat(2, 1fr);

                .account-my-details-membership {
                    &:first-child {
                        margin: 0;
                    }
                }
            }
        }

        .account-previous-upcoming-booking-wrapper {
            .account-previous-upcoming-booking {
                .account-previous-upcoming-title {
                    margin: 0 0 26px;
                    color: $on-primary-light;
                    font-family: $bff-itc-fgs-demi;
                    font-size: 36px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 1; /* 37.35px */
                    letter-spacing: -0.36px;
                    text-transform: uppercase;
                }

                .responsive-table {
                    padding: 0;
                    display: grid;
                    grid-template-columns: 1fr;
                    border: 1px dashed rgba(255, 255, 255, 0.5);

                    .table-row {
                        padding: 0;
                        border-bottom: 1px dashed rgba(255, 255, 255, 0.5);
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr 0.8fr 0.7fr;
                        border-top: 0;

                        &.empty-list {
                            padding: 20px 10px;
                            text-align: center;
                            display: grid !important;
                            grid-template-columns: 1fr;
                            font-size: 16px;
                        }

                        .table-row-cell {
                            display: grid;
                            grid-template-columns: 1fr;
                            border-right: 1px dashed rgba(255, 255, 255, 0.5);

                            &:last-child {
                                border: 0;
                            }

                            .table-row-cell-title {
                                min-width: 111px;
                                padding: 10px 12px 6px;
                                display: none;
                                font-family: $bff-itc-fgs-demi;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 1;
                                letter-spacing: 1.3px;
                                text-transform: uppercase;
                            }

                            .table-row-cell-value {
                                padding: 7px 12px 5px;
                                background-color: $secondary;
                                color: $on-secondary;
                                font-family: $bff-peregrin-denton-light;
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 300;
                                line-height: normal;
                                letter-spacing: 0.6px;
                            }
                        }

                        &:first-child {
                            border-bottom: 1px dashed rgba(255, 255, 255, 0.5);

                            .table-row-cell {
                                .table-row-cell-title {
                                    display: block;
                                    background-color: $primary;
                                    color: $on-primary-light;
                                }
                            }
                        }

                        &:last-child {
                            border-bottom: 0;
                        }
                    }

                    @include device(xs, s) {
                        gap: 15px;
                        border: 0;

                        .table-row {
                            grid-template-columns: 1fr;
                            border: 1px dashed rgba(255, 255, 255, 0.5) !important;

                            &:first-child {
                                display: none;
                            }

                            .table-row-cell {
                                display: grid;
                                grid-template-rows: unset;
                                grid-template-columns: 1fr 1.4fr;
                                border: 0;

                                .table-row-cell-title {
                                    display: block;
                                    font-size: 12px;
                                    border-bottom: 1px dashed rgba(255, 255, 255, 0.5);
                                    border-right: 1px dashed rgba(255, 255, 255, 0.5);
                                }

                                .table-row-cell-value {
                                    padding: 8px 12px;
                                    border-bottom: 1px dashed rgba(255, 255, 255, 0.5);
                                }

                                &:last-child {

                                    .table-row-cell-title,
                                    .table-row-cell-value {
                                        border-bottom: 0;
                                    }
                                }
                            }
                        }
                    }
                }

                &:first-child {
                    margin: 0 0 50px;
                }
            }
        }


        @include device(xs) {
            padding: 30px 20px;
        }

        @include device(s) {
            padding: 40px 30px;
        }

        @include device(m) {
            padding: 50px 40px;
        }

        @include device(m) {
            padding: 50px 50px;
        }

        @include device(l) {
            padding: 60px 60px;
        }

        @include device(xs, s, m, l) {
            grid-template-columns: 1fr;
        }
    }

    .account-loader {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            width: 80px;
            height: 80px;
            align-self: center;
        }
    }
}