@import 'src/styles/variables';


.footer-wrapper {

  .navigation-wrapper {
    padding: 18px 8px 12px;
    height: 41px;
    position: relative;
    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: space-evenly;
    color: $on-tertiary-dark;
    background-color: $tertiary;

    .nav-link {
      color: $on-tertiary-dark;
      font-family: $bff-itc-fgs-demi;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 83%; /* 10.79px */
      letter-spacing: 1.3px;
      text-transform: uppercase;
      text-decoration: none;
    }

    .vertical-divider {
      margin: -6px 0 -1px;
      height: 17px;
      border-right: 2px solid $black;
    }
  }

  .footer-pattern {
    height: 129px;
    width: 100%;
    display: block;
    background-image: url('../../assets/images/footer-pattern-mobile.png');
    background-color: $merky-fc-light-black;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom right;

    @include device(xs) {
      height: 74px;
    }
    @include device(m, l, xl, xxl, xxxl) {
      background-image: url('../../assets/images/footer-pattern.png');
    }
  }
}