@import 'src/styles/variables';


.contact-us-container {
  width: inherit;
  // because of margin-top -1px for sticky header
  height: calc(100vh + 1px);
  display: grid;
  justify-content: center;
  align-items: center;
  overflow: auto;

  .contact-us-wrapper {
    position: relative;
    width: 100%;
    max-width: 390px;
    height: max-content;
    background-color: $merky-fc-solid-white;


    .contact-us-header {
      padding: 52px 16px 50px;

      .contact-us-modal-close-btn {
        position: absolute;
        top: 12px;
        right: 12px;
        width: 24px;
        height: 24px;
        z-index: 1;
        cursor: pointer;
        user-select: none;
        -webkit-user-select: none;

        &:hover {
          svg path {
            fill: $link;
          }
        }
      }

      .contact-us-title {
        margin: 6px 0 20px;
        color: $on-merky-fc-solid-white;
        font-family: $bff-itc-fgs-demi;
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: 83%; /* 29.88px */
        letter-spacing: -0.36px;
        text-transform: uppercase;
      }

      .contact-us-description {
        color: $on-merky-fc-solid-white;
        font-family: $bff-itc-fgs-book;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 2.4px;
        text-transform: uppercase;
      }
    }

    .contact-us-form {
      padding: 50px 16px 35px;
      display: grid;
      grid-template-columns: 1fr;
      gap: 12px;
      @include dashedBorder(t, b);

      .contact-us-form-textarea {
        margin-bottom: 40px;
        width: 100%;
        height: 292px;

        textarea {
          padding: 20px 16px;
          width: inherit;
          height: inherit;
          border: 1px solid rgba(69, 69, 69, 0.30);
          background: rgba(255, 255, 255, 0.30);
          box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.15);
          resize: none;
          outline: none;
          color: $merky-fc-light-black;
          font-family: $bff-peregrin-denton-light;
          font-size: 12px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
          letter-spacing: 0.6px;
        }
      }

      .contact-us-form-item {
        width: 100%;
        height: 100%;

        .contact-us-form-item-input-field {
          padding: 10px 12px 4px;
          width: 100%;
          height: 45px;
          color: $merky-fc-solid-black;
          font-family: $bff-itc-fgs-book;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 1.5px;
          text-transform: uppercase;
          background: $merky-fc-solid-white;
          border: 5px solid #000;
          border-radius: 12px;
          outline: none;
        }
      }

      .terms-condition-container {
        .terms-condition-wrapper {
          display: flex;
          gap: 10px;
          align-items: center;
          user-select: none;
          -webkit-user-select: none;

          .terms-condition-checkbox {
            width: 34px;
            height: 34px;
            cursor: pointer;

            .terms-condition-checkbox-input {
              display: none;
            }

            .terms-condition-checkbox-icon {
              width: 100%;
              height: 100%;
              cursor: pointer;
            }
          }

          .contact-us-form-item-tc {
            margin: 6px 0 0;
            color: $merky-fc-solid-black;
            font-family: $bff-itc-fgs-book;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 1.5px;
            text-transform: uppercase;

            .contact-us-form-item-tc-link {
              text-decoration: underline;
              color: $merky-fc-solid-black;
            }
          }
        }
      }

      .contact-us-form-action {
        padding: 10px 0 0;
        height: 100%;
        width: 100%;

        .contact-us-form-submit-btn {
          padding: 4px 0 0;
          width: 100%;
          height: 45px;
          font-family: $bff-itc-fgs-book;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 2.4px;
          text-transform: uppercase;
          background: $merky-fc-light-black;
          color: $merky-fc-gray;
          border-radius: 16px;
          outline: 0;
          border: 3px solid $border-light-black;
          cursor: pointer;
        }
      }

      .contact-us-form-item-input-err-msg {
        margin: 3px 0 0 8px;
        color: $error;
        font-family: $bff-peregrin-denton-light;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.24px;
      }
    }

    .contact-us-footer {
      padding: 40px 16px 30px;

      .contact-us-venue-map {
        width: 100%;
        // height: 358px;
        aspect-ratio: 1/1;
        box-shadow: 1px 1px 4px 2px rgba(0, 0, 0, 0.10);

        .contact-us-venue-map-img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
    }


    @include device(xs) {
      max-width: 450px;
    }
  }

  .contact-us-sbm-wrapper {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    max-width: 930px;
    height: 100%;
    max-height: 620px;

    .contact-us-sbm-modal-close-btn {
      position: absolute;
      top: 12px;
      right: 12px;
      width: 24px;
      height: 24px;
      z-index: 1;
      cursor: pointer;
      user-select: none;
      -webkit-user-select: none;

      &:hover {
        svg path {
          fill: $link;
        }
      }
    }

    .contact-us-sbm-poster-wrapper {
      width: 100%;
      height: 100%;
      overflow: hidden;

      .contact-us-sbm-poster {
        display: block;
        height: 100%;
        width: 100%;
        content: url('../../assets/images/request-submit.png');
        object-fit: cover;
        object-position: top center;
      }
    }

    .contact-us-sbm-section-wrapper {
      padding: 30px 30px;
      background-color: $modal-primary;

      .contact-us-sbm-section {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        justify-content: space-between;

        .contact-us-sbm-section-title {
          margin: 15px 15px 20px 0;
          color: $on-modal-primary;
          font-family: $bff-itc-fgs-demi;
          font-size: 70px;
          font-style: normal;
          font-weight: 600;
          line-height: 83%; /* 58.1px */
          letter-spacing: -0.7px;
          text-transform: uppercase;
        }

        .contact-us-sbm-section-description {
          padding: 4px 0 0;
          color: $on-modal-primary;
          font-family: $bff-itc-fgs-book;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 83%; /* 14.94px */
          letter-spacing: 1.8px;
          text-transform: uppercase;

          &.mb {
            margin-bottom: 20px;
          }
        }

        .contact-us-sbm-section-action {
          width: 100%;
          height: auto;

          .contact-us-sbm-section-submit-btn {
            padding: 4px 0 0;
            width: 100%;
            height: 45px;
            background: $secondary;
            color: $on-secondary-dark;
            font-family: $bff-itc-fgs-book;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 83%; /* 14.94px */
            letter-spacing: 1.8px;
            text-transform: uppercase;
            border: 3px solid $border-gray-33;
            border-radius: 16px;
            outline: 0;
            cursor: pointer;
          }
        }
      }
    }
  }

  @include device(xs) {
    @include touchScreenScrollbar;
  }

  @include device(xs, s, m) {
    height: calc(100dvh + 1px);

    .contact-us-sbm-wrapper {
      grid-template-columns: unset;
      grid-template-rows: max-content 1fr;
      max-width: 390px;
      max-height: 745px;

      .contact-us-sbm-poster-wrapper {
        .contact-us-sbm-poster {
          content: url('../../assets/images/request-submit-m.png');
        }
      }

      .contact-us-sbm-section-wrapper {
        padding: 25px 16px;

        .contact-us-sbm-section {
          .contact-us-sbm-section-title {
            font-size: 45px;
            letter-spacing: -0.45px;
          }
        }
      }
    }
  }
}