@import 'src/styles/_variables.scss';

.account-sign-up-in-container {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    background-color: $primary;

    .account-sign-up-in-wrapper {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;

        .account-sign-up-in-item {
            padding: 36px 0 0;
            margin: 0 auto;
            width: 100%;
            height: 100%;
            display: grid;
            place-content: center;
            background-color: $primary;
            color: $on-primary;
            font-family: $bff-itc-fgs-demi;
            font-size: 220px;
            font-style: normal;
            font-weight: 600;
            line-height: 74%; /* 162.8px */
            letter-spacing: -6.6px;
            text-transform: uppercase;
            text-align: center;
            text-decoration: none;
            overflow: hidden;
            cursor: pointer;

            .account-sign-up-in-item-img {
                width: 100%;
                height: 220px;
            }

            &:hover {
                background-color: $link;
                color: $merky-fc-light-black;
            }

            &:first-child {
                @include dashedBorder(r);
            }
        }

        @include device(xs) {
            grid-template-columns: 1fr;

            .account-sign-up-in-item {
                font-size: 120px;
                line-height: normal;
                letter-spacing: 2px;

                &:first-child {
                    border: 0;
                    @include dashedBorder(b);
                }
            }
        }
        @include device(s, m) {
            grid-template-columns: 1fr;

            .account-sign-up-in-item {
                font-size: 140px;
                line-height: normal;
                letter-spacing: 2px;

                &:first-child {
                    border: 0;
                    @include dashedBorder(b);
                }
            }
        }
        @include device(l) {
            .account-sign-up-in-item {
                font-size: 150px;
                line-height: normal;
                letter-spacing: 2px;
            }
        }
    }
}