@import 'src/styles/_variables.scss';

.guest-form-wrapper {
    .guest-form {
        margin: 0;
        padding: 0;

        .guest-form-header-title {
            margin: 0 0 24px;
            color: $on-primary-light;
            font-family: $bff-itc-fgs-demi;
            font-size: 45px;
            font-style: normal;
            font-weight: 600;
            line-height: 1;
            /* 83%; 37.35px */
            letter-spacing: -0.45px;
            text-transform: uppercase;
        }

        .guest-form-body {
            margin: 0 0 35px;
            display: grid;
            gap: 16px 0;

            .guest-form-body-item {
                width: 100%;
                height: 40px;

                .guest-form-body-item-input {
                    padding: 12px 20px 8px;
                    width: 100%;
                    height: 100%;
                    border-radius: 12px;
                    border: 3px solid $border-light-black;
                    outline: 0;
                    background: $secondary;
                    color: $on-secondary;
                    font-family: $bff-itc-fgs-book;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 1;
                    /* 83%; 14.94px */
                    letter-spacing: 1.8px;
                    text-transform: uppercase;

                    &[type="password"]:not(:placeholder-shown) {
                        letter-spacing: 5px;
                    }
                }
            }

            .guest-form-body-item-err-msg {
                margin: 6px 0 0 8px;
                color: $error;
                opacity: 0.7;
                font-family: $bff-peregrin-denton-light;
                font-size: 14px;
                font-style: normal;
                font-weight: 300;
                line-height: 1;
                letter-spacing: 0.24px;
            }
        }
    }

    .guest-action-wrapper {
        user-select: none;
        -webkit-user-select: none;

        .guest-action {
            width: 100%;
            height: 45px;

            .guest-action-btn {
                padding: 14px 26px;
                width: 100%;
                height: 100%;
                outline: 0;
                border-radius: 16px;
                border: 3px solid $border-light-black;
                font-family: $bff-itc-fgs-book;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 1;
                letter-spacing: 1.8px;
                text-transform: uppercase;
                color: $on-secondary-dark;
                background: $secondary;
                cursor: pointer;

                &.in-valid {
                    opacity: 0.6;
                    cursor: not-allowed;
                }
            }
        }
    }

    @include device(xs, s, m) {
        .guest-form {
            .guest-form-header-title {
                font-size: 35px;
                letter-spacing: -0.35px;
            }

            .guest-form-body {
                .guest-form-body-item {
                    .guest-form-body-item-input {
                        font-size: 16px;
                        letter-spacing: 0.32px;
                    }
                }
            }
        }
    }
}