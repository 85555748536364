@import 'src/styles/variables';


.forgot-pass-container {
  width: 100%;
  // because of margin-top -1px for sticky header
  height: calc(100vh + 1px);
  display: grid;
  justify-content: center;
  align-items: center;
  overflow: hidden auto;

  .forgot-pass-wrapper {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    max-width: 930px;
    height: 100%;
    max-height: 620px;

    .forgot-pass-modal-close-btn {
      position: absolute;
      top: 12px;
      right: 12px;
      width: 24px;
      height: 24px;
      z-index: 1;
      cursor: pointer;
      user-select: none;
      -webkit-user-select: none;

      &:hover {
        svg path {
          fill: $link;
        }
      }
    }

    .forgot-pass-poster-wrapper {
      height: 100%;
      width: 100%;
      overflow: hidden;

      .forgot-pass-poster {
        display: block;
        width: 100%;
        height: 100%;
        content: url('../../assets/images/forgotten-password.png');
        object-fit: cover;
        object-position: top center;
      }
    }

    .forgot-pass-form-wrapper {
      padding: 30px 30px;
      background-color: $modal-primary;

      .forgot-pass-form {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 50px 0;

        .forgot-pass-form-title {
          margin: 15px 15px 15px 0;
          color: $on-modal-primary;
          font-family: $bff-itc-fgs-demi;
          font-size: 70px;
          font-style: normal;
          font-weight: 600;
          line-height: 83%; /* 58.1px */
          letter-spacing: -0.7px;
          text-transform: uppercase;
        }

        .forgot-pass-form-description {
          padding: 4px 0 0;
          color: $on-modal-primary;
          font-family: $bff-itc-fgs-book;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 83%; /* 14.94px */
          letter-spacing: 1.8px;
          text-transform: uppercase;
        }

        .forgot-pass-form-body {
          display: grid;
          grid-template-columns: 1fr;
          gap: 16px;

          .forgot-pass-form-item {
            position: relative;
            width: 100%;
            height: auto;

            .forgot-pass-form-item-input {
              padding: 9px 12px 4px;
              width: 100%;
              height: 45px;
              color: $merky-fc-solid-black;
              font-family: $bff-itc-fgs-book;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: 83%; /* 14.94px */
              letter-spacing: 1.8px;
              text-transform: uppercase;
              border-radius: 12px;
              border: 5px solid $border-black;
              outline: none;
            }
          }

          .forgot-pass-form-action {
            width: 100%;
            height: auto;

            .forgot-pass-form-submit-btn {
              padding: 6px 0 0;
              width: 100%;
              height: 45px;
              background: $secondary;
              color: $on-secondary-dark;
              font-family: $bff-itc-fgs-book;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: 83%; /* 14.94px */
              letter-spacing: 1.8px;
              text-transform: uppercase;
              border: 3px solid $border-gray-33;
              border-radius: 16px;
              outline: 0;
              cursor: pointer;

              &.in-valid {
                opacity: 0.85;
                cursor: not-allowed;
              }
            }
          }

          .forgot-pass-form-err-msg {
            margin: 4px 0 0 0px;
            color: $error;
            font-family: $bff-peregrin-denton-light;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.24px;
          }
        }
      }
    }
  }

  .forgot-pass-success-wrapper {
    padding: 26px 16px;
    max-width: 450px;
    width: 100%;
    height: max-content;
    background: $modal-primary;

    .forgot-pass-success-title {
      margin: 6px 0 70px;
      color: $on-modal-primary;
      font-family: $bff-itc-fgs-demi;
      font-size: 45px;
      font-style: normal;
      font-weight: 600;
      line-height: 83%; /* 37.35px */
      letter-spacing: -0.45px;
      text-transform: uppercase;
    }

    .forgot-pass-success-btn {
      padding: 4px 0 0;
      width: 100%;
      height: 45px;
      background: $secondary;
      color: $on-secondary-dark;
      font-family: $bff-itc-fgs-book;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 83%; /* 14.94px */
      letter-spacing: 1.8px;
      text-transform: uppercase;
      border: 3px solid $border-gray-33;
      border-radius: 16px;
      outline: 0;
      cursor: pointer;
    }
  }

  @include device(xs) {
    @include touchScreenScrollbar;
  }

  @include device(xs, s, m) {
    height: 100dvh;

    .forgot-pass-wrapper {
      grid-template-columns: unset;
      grid-template-rows: max-content 1fr;
      max-width: 390px;
      max-height: 745px;

      .forgot-pass-poster-wrapper {
        .forgot-pass-poster {
          content: url('../../assets/images/forgotten-password-m.png');
        }
      }

      .forgot-pass-form-wrapper {
        padding: 25px 16px;

        .forgot-pass-form {
          .forgot-pass-form-title {
            font-size: 45px;
            letter-spacing: -0.45px;
          }
        }
      }
    }
  }
}