@import 'src/styles/variables';


.order-failed-container {
  width: 100%;
  height: auto;
  background-color: $primary;


  .order-failed-wrap {
    padding: 100px 90px;
    @include dashedBorder(b);

    .order-failed-title-wrap {
      margin: 0 0 34px;
      display: grid;
      grid-template-columns: max-content 1fr;
      gap: 0 20px;

      .order-failed-title-icon {
        width: 78px;
        height: 78px;

        svg {
          width: inherit;
          height: inherit;
        }
      }

      .order-failed-title {
        margin: 16px 0 0;
        align-self: center;
        color: $on-primary;
        font-family: $bff-itc-fgs-demi;
        font-size: 70px;
        font-style: normal;
        font-weight: 600;
        line-height: 83%; /* 58.1px */
        letter-spacing: -0.7px;
        text-transform: uppercase;
      }
    }

    .order-failed-desc {
      margin: 0 0 60px;
      color: $on-primary-light;
      font-family: $bff-peregrin-denton-light;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 120%; /* 19.2px */
      letter-spacing: 0.8px;
    }

    .order-failed-go-back-btn {
      padding: 4px 0 0;
      width: 100%;
      max-width: 400px;
      height: 45px;
      font-family: $bff-itc-fgs-book;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 83%; /* 14.94px */
      letter-spacing: 1.8px;
      text-transform: uppercase;
      background: $secondary;
      color: $on-secondary-dark;
      border-radius: 16px;
      border: 3px solid $border-light-black;
      outline: 0;
      cursor: pointer;
    }



    @include device (xs) {
      padding: 40px 20px;

      .order-failed-title-wrap {
        gap: 0 10px;
        .order-failed-title-icon {
          width: 33px;
          height: 33px;
        }
        .order-failed-title {
          margin: 8px 0 0;
          font-size: 36px;
          letter-spacing: -0.36px;
        }
      }
      .order-failed-desc {
        font-size: 12px;
        line-height: normal;
        letter-spacing: 0.6px;
      }
      .order-failed-go-back-btn {
        font-size: 15px;
        letter-spacing: 1.5px;
      }
    }

    @include device (s) {
      padding: 55px 37px;

      .order-failed-title-wrap {
        .order-failed-title-icon {
          width: 44px;
          height: 44px;
        }
        .order-failed-title {
          margin: 12px 0 0;
          font-size: 44px;
          letter-spacing: -0.44px;
          line-height: 75%;
        }
      }
      .order-failed-desc {
        font-size: 14px;
        line-height: normal;
        letter-spacing: 0.6px;
      }
      .order-failed-go-back-btn {
        font-size: 16px;
        letter-spacing: 1.6px;
      }
    }

    @include device (m) {
      padding: 70px 55px;

      .order-failed-title-wrap {
        .order-failed-title-icon {
          width: 55px;
          height: 55px;
        }
        .order-failed-title {
          margin: 14px 0 0;
          font-size: 53px;
          letter-spacing: -0.53px;
          line-height: 77%;
        }
      }
      .order-failed-desc {
        font-size: 14px;
        line-height: normal;
        letter-spacing: 0.6px;
      }
      .order-failed-go-back-btn {
        font-size: 16px;
        letter-spacing: 1.6px;
      }
    }

    @include device (l) {
      padding: 85px 72px;

      .order-failed-title-wrap {
        .order-failed-title-icon {
          width: 66px;
          height: 66px;
        }
        .order-failed-title {
          margin: 15px 0 0;
          font-size: 61px;
          letter-spacing: -0.61px;
        }
      }
      .order-failed-go-back-btn {
        font-size: 17px;
        letter-spacing: 1.7px;
      }
    }
  }

  .order-failed-help-wrapper {
    padding: 150px 100px 100px;
    display: grid;
    grid-template-columns: max-content 1fr;
    gap: 0 15px;

    .order-failed-help-icon {
      height: 47px;
      width: 47px;
    }

    .order-failed-help-link {
      margin: 8px 0 0;
      align-self: center;
      color: $on-primary-light;
      font-family: $bff-itc-fgs-book;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 83%; /* 14.94px */
      letter-spacing: 1.8px;
      text-transform: uppercase;

      .link {
        color: $link;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    @include device (xs) {
      padding: 100px 20px 100px;
      gap: 0 10px;

      .order-failed-help-icon {
        height: 32px;
        width: 32px;
      }

      .order-failed-help-link {
        font-size: 15px;
        letter-spacing: 1.5px;
      }
    }

    @include device (s) {
      padding: 112px 40px 100px;

      .order-failed-help-icon {
        height: 35px;
        width: 35px;
      }

      .order-failed-help-link {
        font-size: 16px;
        letter-spacing: 1.6px;
      }
    }

    @include device (m) {
      padding: 125px 60px 100px;

      .order-failed-help-icon {
        height: 39px;
        width: 39px;
      }

      .order-failed-help-link {
        font-size: 16px;
        letter-spacing: 1.6px;
      }
    }

    @include device (l) {
      padding: 137px 80px 100px;

      .order-failed-help-icon {
        height: 42px;
        width: 42px;
      }

      .order-failed-help-link {
        font-size: 17px;
        letter-spacing: 1.7px;
      }
    }
  }
}