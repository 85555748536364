@import 'src/styles/variables';


.membership-purchase-response-container {
  .header-wrap {
    padding: 100px 90px;

    .title-wrap {
      margin: 0 0 34px;
      display: grid;
      grid-template-columns: max-content 1fr;
      gap: 0 20px;

      .title-icon {
        width: 78px;
        height: 78px;

        svg {
          width: inherit;
          height: inherit;
        }
      }

      .title {
        margin: 16px 0 0;
        align-self: center;
        color: $on-primary;
        font-family: $bff-itc-fgs-demi;
        font-size: 70px;
        font-style: normal;
        font-weight: 600;
        line-height: 83%;
        /* 58.1px */
        letter-spacing: -0.7px;
        text-transform: uppercase;
      }
    }

    .description {
      color: $on-primary-light;
      font-family: $bff-peregrin-denton-light;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 120%;
      /* 19.2px */
      letter-spacing: 0.8px;
    }

    @include device (xs) {
      padding: 40px 20px;

      .title-wrap {
        gap: 0 10px;

        .title-icon {
          width: 33px;
          height: 33px;
        }

        .title {
          margin: 8px 0 0;
          font-size: 36px;
          letter-spacing: -0.36px;
        }
      }

      .description {
        margin: 0 0 20px;
        font-size: 12px;
        line-height: normal;
        letter-spacing: 0.6px;
      }
    }

    @include device (s) {
      padding: 55px 37px;

      .title-wrap {
        .title-icon {
          width: 44px;
          height: 44px;
        }

        .title {
          margin: 12px 0 0;
          font-size: 44px;
          letter-spacing: -0.44px;
          line-height: 75%;
        }
      }

      .description {
        margin: 0 0 30px;
        font-size: 14px;
        line-height: normal;
        letter-spacing: 0.6px;
      }
    }

    @include device (m) {
      padding: 70px 55px;

      .title-wrap {
        .title-icon {
          width: 55px;
          height: 55px;
        }

        .title {
          margin: 14px 0 0;
          font-size: 53px;
          letter-spacing: -0.53px;
          line-height: 77%;
        }
      }

      .description {
        margin: 0 0 40px;
        font-size: 14px;
        line-height: normal;
        letter-spacing: 0.6px;
      }
    }

    @include device (l) {
      padding: 85px 72px;

      .title-wrap {
        .title-icon {
          width: 66px;
          height: 66px;
        }

        .title {
          margin: 15px 0 0;
          font-size: 61px;
          letter-spacing: -0.61px;
        }
      }

      .description {
        margin: 0 0 50px;
      }
    }
  }

  .success-img {
    width: 100%;
    height: auto;
    display: block;
  }

  .help-wrapper {
    padding: 100px 100px;
    display: flex;
    flex-wrap: nowrap;
    gap: 0 15px;

    .help-icon {
      height: 47px;
      width: 47px;
    }

    .help-link {
      margin: 6px 0 0;
      align-self: center;
      color: $on-primary-light;
      font-family: $bff-itc-fgs-book;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 83%;
      /* 14.94px */
      letter-spacing: 1.8px;
      text-transform: uppercase;

      .manage-membership-link {
        color: $link;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    @include device (xs) {
      padding: 80px 20px;
      gap: 0 10px;

      .help-icon {
        height: 32px;
        width: 32px;
      }

      .help-link {
        font-size: 15px;
        letter-spacing: 1.5px;
      }
    }

    @include device (s) {
      padding: 80px 40px;

      .help-icon {
        height: 35px;
        width: 35px;
      }

      .help-link {
        font-size: 16px;
        letter-spacing: 1.6px;
      }
    }

    @include device (m) {
      padding: 80px 60px;

      .help-icon {
        height: 39px;
        width: 39px;
      }

      .help-link {
        font-size: 16px;
        letter-spacing: 1.6px;
      }
    }

    @include device (l) {
      padding: 80px 80px;

      .help-icon {
        height: 42px;
        width: 42px;
      }

      .help-link {
        font-size: 17px;
        letter-spacing: 1.7px;
      }
    }
  }
}