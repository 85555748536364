@import 'src/styles/variables';


.venue-enq-container {
  width: 100%;
  // because of margin-top -1px for sticky header
  height: calc(100vh + 1px);
  display: grid;
  justify-content: center;
  align-items: center;
  overflow: hidden auto;

  .venue-enq-wrapper {
    position: relative;
    width: 100%;
    max-width: 390px;
    height: max-content;
    background-color: $merky-fc-solid-white;

    .venue-enq-title {
      color: $on-merky-fc-solid-white;
      font-family: $bff-itc-fgs-demi;
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: 83%;
      /* 29.88px */
      letter-spacing: -0.36px;
      text-transform: uppercase;

      &.mb {
        margin: 6px 0 20px;
      }
    }

    .venue-enq-header {
      padding: 52px 16px 50px;
      @include dashedBorder(b);

      .venue-enq-modal-close-btn {
        position: absolute;
        top: 12px;
        right: 12px;
        width: 24px;
        height: 24px;
        z-index: 1;
        cursor: pointer;
        user-select: none;
        -webkit-user-select: none;

        &:hover {
          svg path {
            fill: $link;
          }
        }
      }

      .venue-enq-description {
        color: $on-merky-fc-solid-white;
        font-family: $bff-itc-fgs-book;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 2.4px;
        text-transform: uppercase;
      }
    }

    .venue-enq-common-wrapper {
      padding: 50px 20px 30px;
      @include dashedBorder(b);

      .venue-enq-common-form-textarea-wrap {
        width: 100%;
        height: 292px;

        &.mb {
          margin-bottom: 50px;
        }

        .venue-enq-common-form-textarea {
          padding: 20px 16px;
          width: inherit;
          height: inherit;
          border: 1px solid rgba(69, 69, 69, 0.30);
          background: rgba(255, 255, 255, 0.30);
          box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.15);
          resize: none;
          outline: none;
          color: $merky-fc-light-black;
          font-family: $bff-peregrin-denton-light;
          font-size: 12px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
          letter-spacing: 0.6px;
        }
      }

      .venue-enq-common-form-inputs-wrap {
        display: grid;
        grid-template-columns: 1fr;
        gap: 12px;

        .venue-enq-common-form-item {
          width: 100%;
          height: 100%;
        }
      }

      .venue-enq-description {
        margin: -6px 0 8%;
        color: $on-merky-fc-solid-white;
        font-family: $bff-itc-fgs-book;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 2.4px;
        text-transform: uppercase;
      }

      /** use at 2 places like outside abd inside of .venue-enq-common-form-inputs-wrap > .venue-enq-common-form-item class  */
      .venue-enq-common-form-item-input-field {
        padding: 10px 12px 4px;
        width: 100%;
        height: 45px;
        color: $merky-fc-solid-black;
        font-family: $bff-itc-fgs-book;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.15px;
        text-transform: uppercase;
        background: $merky-fc-solid-white;
        border: 5px solid $border-black;
        border-radius: 12px;
        outline: none;

        &.extra-height {
          height: 62px;
        }

        &.date-time-input {
          &::before {
            content: attr(placeholder) !important;
            color: #8e8e8e;
            margin-right: 0.5em;
          }
        }
      }

      .venue-enq-tcs-wrapper {
        display: flex;
        gap: 10px;
        align-items: center;
        user-select: none;
        -webkit-user-select: none;

        .venue-enq-tcs-checkbox {
          width: 34px;
          height: 34px;
          cursor: pointer;

          .venue-enq-tcs-checkbox-input {
            display: none;
          }

          .venue-enq-tcs-checkbox-icon {
            width: 100%;
            height: 100%;
            cursor: pointer;
          }
        }

        .venue-enq-tcs-text {
          margin: 6px 0 0;
          color: $merky-fc-solid-black;
          font-family: $bff-itc-fgs-book;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 1.5px;
          text-transform: uppercase;

          .venue-enq-tcs-link {
            text-decoration: underline;
            color: $merky-fc-solid-black;
          }
        }
      }

      .venue-enq-action-wrap {
        padding: 26px 0 0;
        height: 100%;
        width: 100%;

        .venue-enq-submit-btn {
          padding: 4px 0 0;
          width: 100%;
          height: 45px;
          font-family: $bff-itc-fgs-book;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 2.4px;
          text-transform: uppercase;
          background: $merky-fc-light-black;
          color: $merky-fc-gray;
          border-radius: 16px;
          outline: 0;
          border: 3px solid $border-light-black;
          cursor: pointer;
        }
      }
    }

    .venue-enq-form-item-input-err-msg {
      margin: 3px 0 0 4px;
      color: $error;
      font-family: $bff-peregrin-denton-light;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.24px;
    }


    @include device(xs) {
      max-width: 450px;
    }
  }

  .venue-req-sbm-wrapper {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    max-width: 930px;
    height: 100%;
    max-height: 620px;

    .venue-req-sbm-modal-close-btn {
      position: absolute;
      top: 12px;
      right: 12px;
      width: 24px;
      height: 24px;
      z-index: 1;
      cursor: pointer;
      user-select: none;
      -webkit-user-select: none;

      &:hover {
        svg path {
          fill: $link;
        }
      }
    }

    .venue-req-sbm-poster-wrapper {
      width: 100%;
      height: 100%;
      overflow: hidden;

      .venue-req-sbm-poster {
        display: block;
        height: 100%;
        width: 100%;
        content: url('../../assets/images/request-submit.png');
        object-fit: cover;
        object-position: top center;
      }
    }

    .venue-req-sbm-section-wrapper {
      padding: 30px 30px;
      background-color: $modal-primary;

      .venue-req-sbm-section {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        justify-content: space-between;

        .venue-req-sbm-section-title {
          margin: 15px 15px 15px 0;
          color: $on-modal-primary;
          font-family: $bff-itc-fgs-demi;
          font-size: 70px;
          font-style: normal;
          font-weight: 600;
          line-height: 83%;
          /* 58.1px */
          letter-spacing: -0.7px;
          text-transform: uppercase;
        }

        .venue-req-sbm-section-description {
          padding: 4px 0 0;
          color: $on-modal-primary;
          font-family: $bff-itc-fgs-book;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 83%;
          /* 14.94px */
          letter-spacing: 1.8px;
          text-transform: uppercase;

          &.mb {
            margin-bottom: 20px;
          }
        }

        .venue-req-sbm-section-action {
          width: 100%;
          height: auto;

          .venue-req-sbm-section-submit-btn {
            padding: 4px 0 0;
            width: 100%;
            height: 45px;
            background: $secondary;
            color: $on-secondary-dark;
            font-family: $bff-itc-fgs-book;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 83%;
            /* 14.94px */
            letter-spacing: 1.8px;
            text-transform: uppercase;
            border: 3px solid $border-gray-33;
            border-radius: 16px;
            outline: 0;
            cursor: pointer;
          }
        }
      }
    }
  }

  @include device(xs) {
    @include touchScreenScrollbar;
  }

  @include device(xs, s, m) {
    height: calc(100dvh + 1px);

    .venue-req-sbm-wrapper {
      grid-template-columns: unset;
      grid-template-rows: max-content 1fr;
      max-width: 390px;
      max-height: 745px;

      .venue-req-sbm-poster-wrapper {
        .venue-req-sbm-poster {
          content: url('../../assets/images/request-submit-m.png');
        }
      }

      .venue-req-sbm-section-wrapper {
        padding: 25px 16px;

        .venue-req-sbm-section {
          .venue-req-sbm-section-title {
            font-size: 45px;
            letter-spacing: -0.45px;
          }
        }
      }
    }
  }
}

.venue-room-selection-wrapper {
  display: flex;
  flex-direction: column;
  gap: 21px 0;

  .vr-selection-checkbox-wrap {
    display: flex;
    align-items: center;
    gap: 14px;
    border-bottom: 1px solid;

    .vr-selection-checkbox-icon-wrap {
      flex-shrink: 0;

      .vr-selection-checkbox-icon {
        display: block;
        width: 34px;
        height: 34px;
        cursor: pointer;
      }
    }

    .vr-selection-checkbox-label {
      flex: 1;
      margin: 5px 0 0;
      color: $on-merky-fc-solid-white;
      font-family: $bff-itc-fgs-book;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 1;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
  }
}