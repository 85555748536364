@import 'src/styles/_variables.scss';

.home-wrapper {
    width: 100%;
    height: 100%;
    background-color: $primary;

    .hero-section {
        position: relative;
        width: auto;
        min-width: 100%;
        height: auto;
        min-height: calc(100vh - 52px);
        overflow: hidden;

        .hero-img {
            width: 100%;
            min-height: 100%;
            height: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 1;
            transform: translate(-50%, -50%);
            content: url('../../assets/images/home-hero.png');
            overflow: hidden;
            object-fit: cover;
            object-position: center;
        }
    }

    .welcome-section {
        position: relative;
        width: 100%;
        height: auto;
        overflow: hidden;

        .welcome-section-img-wrapper {
            width: 100%;
            height: max-content;
            padding: 35px 126px;
            text-align: center;
            background-image: url('../../assets/images/welcome-merky-fc-background.png');
            background-size: cover;
            background-position: top left;
            background-repeat: no-repeat;

            .welcome-section-img {
                width: 100%;
                height: 100%;
                content: url('../../assets/images/welcome-home-of-merkyfc.png');
                overflow: hidden;
                object-fit: cover;
                object-position: center;
            }
        }

        @include device(xs) {
            .welcome-section-img-wrapper {
                padding: 34px 30px;
                background-image: url('../../assets/images/welcome-merky-fc-background-m.png');
    
                .welcome-section-img {
                    content: url('../../assets/images/welcome-home-of-merkyfc-m.png');
                }
            }
        }
        @include device(s) {
            .welcome-section-img-wrapper {
                padding: 30px 60px;
            }
        }
        @include device(m, l) {
            .welcome-section-img-wrapper {
                padding: 35px 90px;
            }
        }

    }

    .image-slider-container {
        margin: 110px 0px 100px 0px;
        width: 100%;
        max-width: 1428px;
        overflow-x: scroll;

        -ms-overflow-style: none;
        /* for Internet Explorer, Edge */
        scrollbar-width: none;
        /* for Firefox */
        &::-webkit-scrollbar {
            display: none;
            /* for Chrome, Safari, and Opera */
        }
        .image-slider-wrapper {
            padding: 0 0 0 70px;
            display: inline-flex;
            flex-direction: row;
            gap: 15px;

            .image-item {
                width: 725px;
                height: 725px;
                user-select: none;
                -webkit-user-select: none;

                .image-item-img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    object-position: center;
                    -webkit-user-drag: none; /* Safari */
                }
            }
        }
        @include device(xs, s, m, l, xl){
            max-width: calc(100vw - 15px);
        }

        @include device(xs) {
            margin: 55px 0px 35px 0px;
            .image-slider-wrapper {
                padding: 0 0 0 15px;
                .image-item {
                    width: 295px;
                    height: 295px;
                }
            }
        }

        @include device(s) {
            margin: 66px 0px 48px 0px;
            .image-slider-wrapper {
                padding: 0 0 0 26px;
                .image-item {
                    width: 381px;
                    height: 381px;
                }
            }
        }

        @include device(m) {
            margin: 77px 0px 61px 0px;
            .image-slider-wrapper {
                padding: 0 0 0 37px;
                .image-item {
                    width: 467px;
                    height: 467px;
                }
            }
        }

        @include device(l) {
            margin: 88px 0px 74px 0px;
            .image-slider-wrapper {
                padding: 0 0 0 48px;
                .image-item {
                    width: 553px;
                    height: 553px;
                }
            }
        }

        @include device(xl) {
            margin: 99px 0px 87px 0px;
            .image-slider-wrapper {
                padding: 0 0 0 59px;
                .image-item {
                    width: 639px;
                    height: 639px;
                }
            }
        }
    }

    .home-about-us-wrapper {
        padding: 10px 110px 110px;

        .about-us-title {
            margin-bottom: 27px;
            color: $on-primary;
            font-family: $bff-itc-fgs-demi;
            font-size: 70px;
            font-style: normal;
            font-weight: 600;
            line-height: 1; /* 29.88px */
            letter-spacing: -0.7px;
            text-transform: uppercase;
        }

        .about-us-description {
            color: $on-primary;
            font-family: $bff-itc-fgs-demi;
            font-size: 45px;
            font-style: normal;
            font-weight: 600;
            line-height: 1;
            letter-spacing: -0.45px;
            text-transform: uppercase;
        }

        @include device(xs) {
            padding: 10px 15px 60px;

            .about-us-title {
                margin-bottom: 15px;
                font-size: 36px;
                letter-spacing: -0.36px;
            }
            .about-us-description {
                font-size: 15px;
                letter-spacing: 1.5px;
            }
        }

        @include device(s) {
            padding: 10px 34px 70px;

            .about-us-title {
                margin-bottom: 15px;
                font-size: 42px;
                letter-spacing: -0.44px;
            }
            .about-us-description {
                font-size: 21px;
            }
        }
        
        @include device(m) {
            padding: 10px 53px 80px;
            
            .about-us-title {
                margin-bottom: 19px;
                font-size: 51px;
                letter-spacing: -0.53px;
            }
            .about-us-description {
                font-size: 27px;
            }
        }
        
        @include device(l) {
            padding: 10px 72px 90px;
            
            .about-us-title {
                margin-bottom: 19px;
                font-size: 59px;
                letter-spacing: -0.61px;
            }
            .about-us-description {
                font-size: 33px;
            }
        }

        @include device(xl) {
            padding: 10px 91px 100px;

            .about-us-title {
                margin-bottom: 23px;
                font-size: 70px;
                letter-spacing: -0.7px;
            }
            .about-us-description {
                font-size: 39px;
            }
        }

        @include device(s, m, l, xl) {
            .about-us-description {
                letter-spacing: 0.32px;
            }
        }
    }

    .home-booking-wrapper {
        display: grid;
        grid-template-columns: repeat(2, minmax(300px,1fr));
        grid-auto-flow: row;

        .home-booking-item {
            position: relative;
            width: 100%;
            aspect-ratio : 1 / 1.2;
            overflow: hidden;
            @include dashedBorder(b);
            cursor: pointer;
            
            &:hover .home-booking-item-img {
                transform: scale(1.1);
            }

            .home-booking-item-img {
                width: 100%;
                height: 100%;
                transition: transform 1s;
                user-select: none;
                -webkit-user-select: none;
            }

            .home-booking-item-details {
                padding: 0 55px 50px;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;

                .home-booking-item-title {
                    // margin-bottom: 15px;
                    color: $on-primary-light;
                    font-family: $bff-itc-fgs-demi;
                    font-size: 45px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 1; /* 69.3px */
                    letter-spacing: -0.45px;
                    text-transform: uppercase;
                }

                .home-booking-item-description {
                    color: $on-primary-light;
                    font-family: $base-font-family-ad;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.32px;
                }
            }

            .home-venue-hire-link {
                padding: 44px 0 0;
                display: grid;
                width: 100%;
                height: 100%;
                place-content: center;
                text-align: center;
                color: $on-primary;
                font-family: $bff-itc-fgs-demi;
                font-size: 45px;
                font-style: normal;
                font-weight: 600;
                line-height: 74%; /* 148px */
                letter-spacing: 0.8px;
                text-decoration-line: none;
                text-transform: uppercase;
                cursor: initial !important;
                // &:hover {
                //     background-color: $merky-fc-yellow;
                //     color: $merky-fc-light-black;
                // }
                @include device(xs, s, m) {
                    font-size: 45px;
                    // letter-spacing: -5.1px;
                }
            }
        }

        @include device(xs) {
            grid-template-columns: minmax(300px,1fr);
            .home-booking-item {
                aspect-ratio : 1 / 1;
                @include dashedBorder(b);
                &:first-child {
                    @include dashedBorder(t);
                }
                .home-booking-item-details {
                    padding: 0 15px 10px;

                    .home-booking-item-title {
                        font-size: 36px;
                        letter-spacing: -0.36px;
                    }
                }
            }
        }
        @include device(s) {
            grid-template-columns: minmax(300px,1fr);
            .home-booking-item {
                aspect-ratio : 1 / 1;
                @include dashedBorder(b);
                &:first-child {
                    @include dashedBorder(t);
                }
                .home-booking-item-details {
                    padding: 0 30px 25px;

                    .home-booking-item-title {
                        font-size: 39px;
                        letter-spacing: -0.39px;
                    }
                }
            }
        }
        @include device(m, l) {
            grid-template-columns: repeat(2, minmax(300px,1fr));
            .home-booking-item {
                @include dashedBorder(b);
                &:nth-child(odd) {
                    @include dashedBorder(r);
                }
                &:nth-child(-n + 2) {
                    @include dashedBorder(t);
                }
                .home-booking-item-details {
                    padding: 0 20px 20px;

                    .home-booking-item-title {
                        font-size: 42px;
                        letter-spacing: -0.42px;
                    }
                }
            }
        }
        @include device(l) {
            grid-template-columns: repeat(2, minmax(300px,1fr));
            .home-booking-item {
                .home-booking-item-details {
                    padding: 0 35px 35px;
                }
            }
        }
        @include device(xl, xxl, xxxl) {
            .home-booking-item {
                &:nth-child(odd) {
                    @include dashedBorder(r);
                }
                &:nth-child(-n + 2) {
                    @include dashedBorder(t);
                }
            }
        }
    }

    .home-venue-map-wrapper {
        padding: 110px 110px;

        .home-venue-map {
            width: 100%;
            aspect-ratio: 16/9;
            background-color: #E6FF9F;

            .home-venue-map-img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }

        @include device(xs) {
            padding: 50px 10px;
        }

        @include device(s) {
            padding: 62px 30px;
        }

        @include device(m) {
            padding: 74px 50px;
        }

        @include device(l) {
            padding: 86px 70px;
        }

        @include device(xl) {
            padding: 98px 90px;
        }
    }

    .home-address-container {
        position: relative;
        padding: 100px 25px 60px;
        width: 100%;
        height: auto;
        @include dashedBorder(t);

        .footer-logo {
            /** desktop view */
            width: 950px;
            height: 350px;
            position: absolute;
            top: -280px;
            right: 0px;
            z-index: 21;
            background-image: url('../../assets/images/hashtag-merkyfc.svg');
            background-repeat: no-repeat;
            background-position: 0px calc(50% - 20px);
            background-size: cover;

            @include device(xs) {
                /** mobile view */
                width: 290px;
                height: 150px;
                position: absolute;
                top: -105px; //-113px;
                right: 0;
                z-index: 21;
                background-image: url('../../assets/images/hashtag-merkyfc.svg');
                background-repeat: no-repeat;
                background-position: -100px calc(50% - 10px);
                background-size: unset;
            }

            @include device(s) {
                /** mobile view */
                width: 460px;
                height: 170px;
                top: -123px;
                background-position: 0px calc(50% - 10px);
            }

            @include device(m) {
                /** mobile view */
                width: 550px;
                height: 200px;
                top: -145px;
                background-position: 0px calc(50% - 15px);
            }

            @include device(l) {
                /** mobile view */
                width: 686px;
                height: 240px;
                top: -175px;
                background-position: 0px calc(50% - 20px);
            }

            @include device(xl) {
                /** mobile view */
                width: 850px;
                height: 300px;
                top: -225px;
                background-position: 0px calc(50% - 20px);
            }
        }

        .home-address-wrapper {
            width: 100%;
            height: auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            gap: 50px 30px;

            .home-address-item {
                display: grid;
                grid-template-rows: max-content 1fr;
                gap: 40px;

                .address-label {
                    color: $on-primary;
                    font-family: $bff-itc-fgs-book;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 1; /* 14.94px */
                    letter-spacing: 1.8px;
                    text-transform: uppercase;
                }

                .address-details {
                    color: $on-primary;
                    font-family: $bff-itc-fgs-demi;
                    font-size: 45px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 83%;
                    letter-spacing: -0.45px;
                    text-transform: uppercase;
                }
            }

            .home-address-divider {
                border-right: 2px solid $border-gray;
            }

            @include device(xs, s) {
                flex-direction: column;

                .home-address-divider {
                    display: none;
                }
            }

            @include device(xs, s, m) {
                .home-address-item {
                    gap: 25px;

                    .address-label {
                        font-size: 16px;
                        letter-spacing: 2.4px;
                    }

                    .address-details {
                        font-size: 36px;
                        letter-spacing: -0.36px;
                    }
                }
            }
        }

        @include device(xs, s, m) {
            padding: 70px 25px 60px;
        }
    }

}

@include device(xs) {
    .home-wrapper {
        .hero-section {
            .hero-img {
                content: url('../../assets/images/home-hero-m.png');
            }
        }

    }
}

@include device(l, xl, xxl, xxxl) {
    .home-wrapper {
        .hero-section {
            min-height: calc(100vh - 76px);
        }
    }
}