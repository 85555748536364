@import '_colors';

/** Application shadows */
@mixin shadow {
    // box-shadow: rgb(0 0 0 / 12%) 0px 1px 3px, rgb(0 0 0 / 24%) 0px 1px 2px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
}




/** Responsive media queries */
@mixin device($breakpoints...) {

    @each $breakpoint in $breakpoints {
        @if $breakpoint ==xs {
            @media only screen and (max-width: 575px) {
                @content;
            }
        }

        @if $breakpoint ==s {
            @media only screen and (min-width: 575.01px) and (max-width: 768px) {
                @content;
            }
        }

        @if $breakpoint ==m {
            @media only screen and (min-width: 768.01px) and (max-width: 992px) {
                @content;
            }
        }

        @if $breakpoint ==l {
            @media only screen and (min-width: 992.01px) and (max-width: 1280px) {
                @content;
            }
        }

        @if $breakpoint ==xl {
            @media only screen and (min-width: 1280.01px) and (max-width: 1440px) {
                @content;
            }
        }

        @if $breakpoint ==xxl {
            @media only screen and (min-width: 1440.01px) and (max-width: 1600px) {
                @content;
            }
        }

        @if $breakpoint ==xxxl {
            @media only screen and (min-width: 1600px) {
                @content;
            }
        }
    }

}

/** border dashed */
@mixin dashedBorder($breakpoints...) {
    @each $breakpoint in $breakpoints {
        @if $breakpoint ==t {
            border-top: 1px dashed $border-gray;
        }

        @if $breakpoint ==b {
            border-bottom: 1px dashed $border-gray;
        }

        @if $breakpoint ==l {
            border-left: 1px dashed $border-gray;
        }

        @if $breakpoint ==r {
            border-right: 1px dashed $border-gray;
        }

        @if $breakpoint ==all {
            border: 1px dashed $border-gray;
        }
    }
}


/** For touch screen device browser */
@mixin touchScreenScrollbar {
    /* width */
    &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
        background-color: whitesmoke;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #A2A4A4;
        border-radius: 4px;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #B1B2B2;
    }
}