@import 'src/styles/variables';

.venue-hire-container {
    width: 100%;
    height: auto;
    background-color: $primary;

    .venue-hire-head-wrapper {
        padding: 85px 105px 100px;
        width: 100%;
        height: auto;

        .venue-hire-head-title {
            margin: 8px 0 26px;
            color: $on-primary;
            font-family: $bff-itc-fgs-demi;
            font-size: 70px;
            font-style: normal;
            font-weight: 600;
            line-height: 83%; /* 58.1px */
            letter-spacing: -0.7px;
            text-transform: uppercase;
        }

        .venue-hire-head-description {
            color: $on-primary-light;
            font-family: $bff-peregrin-denton-light;
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: 120%; /* 19.2px */
            letter-spacing: 0.8px;
        }

        @include device (xs) {
            .venue-hire-head-title {
                font-size: 36px;
                letter-spacing: -0.36px;
            }

            .venue-hire-head-description {
                font-size: 12px;
                line-height: normal;
                letter-spacing: 0.6px;
            }
        }

        @include device (s) {
            .venue-hire-head-title {
                font-size: 43px;
                /* 29.05px */
                letter-spacing: 0.25px;
            }

            .venue-hire-head-description {
                font-size: 14px;
                line-height: normal;
                letter-spacing: 0.6px;
            }
        }

        @include device (m) {
            .venue-hire-head-title {
                font-size: 52px;
                /* 29.05px */
                letter-spacing: 0.15px;
            }

            .venue-hire-head-description {
                font-size: 14px;
                line-height: normal;
                letter-spacing: 0.6px;
            }
        }

        @include device (l) {
            .venue-hire-head-title {
                font-size: 60px;
                /* 29.05px */
                letter-spacing: 0.05px;
            }

            // .venue-hire-head-description {
            //     font-size: 27px;
            // }
        }
    }

    .venue-hire-room-container {
        padding-bottom: 100px;

        .venue-hire-room-wrapper {

            .venue-hire-room-hero-img-wrapper {
                @include dashedBorder(b);
                @include dashedBorder(t);
                height: 864px;
                background: linear-gradient(0deg, #5B5B5B 0%, #5B5B5B 100%), lightgray -7.081px -10px / 102.372% 102.315% no-repeat;
                // @include dashedBorder(b);

                .venue-hire-room-hero-img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }

                .enquire-now-wrapper {
                    padding: 80px 105px;
                    position: absolute;
                    bottom: 0;
                    user-select: none;
                    -webkit-user-select: none;

                    .enquire-now-head {
                        padding: 0 0 10px;
                        color: $on-primary-light;
                        font-family: $bff-itc-fgs-demi;
                        font-size: 45px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 83%; /* 37.35px */
                        letter-spacing: -0.45px;
                        text-transform: uppercase;
                    }

                    .enquire-now-content {
                        padding: 4px 0 0;
                        color: $on-primary-light;
                        font-family: $bff-itc-fgs-book;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 83%; /* 14.94px */
                        letter-spacing: 1.8px;
                        text-transform: uppercase;

                        .enquire-now-link {
                            color: $link;
                            text-decoration-line: underline;
                            cursor: pointer;
                        }
                    }


                }
            }

            .venue-hire-room-more-img-container {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;

                .venue-hire-room-more-img-wrapper {
                    height: 480px;
                    background: linear-gradient(0deg, #5B5B5B 0%, #5B5B5B 100%), lightgray -7.081px -10px / 102.372% 102.315% no-repeat;
                    @include dashedBorder(b);
                    /** assign right border if sub-image has only 1 image */
                    &:only-child {
                        @include dashedBorder(r);
                    }

                    &:nth-child(even) {
                        background: linear-gradient(0deg, #999 0%, #999 100%), lightgray -2.364px -5.59px / 102.372% 102.315% no-repeat;
                        @include dashedBorder(l, r);
                    }

                    .venue-hire-room-more-img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }



            @include device(xs) {
                .venue-hire-room-hero-img-wrapper {
                    height: 650px;
                    border: none;

                    .enquire-now-wrapper {
                        padding: 18px 16px;

                        .enquire-now-head {
                            padding: 0 0 4px;
                            font-size: 36px;
                            letter-spacing: -0.36px;
                        }

                        .enquire-now-content {
                            font-size: 15px;
                            letter-spacing: 1.5px;
                        }
                    }
                }

                .venue-hire-room-more-img-container {
                    grid-template-columns: 1fr;

                    .venue-hire-room-more-img-wrapper {
                        height: 390px;

                        &:nth-child(odd) {
                            background: linear-gradient(0deg, #999 0%, #999 100%), lightgray -2.364px -5.59px / 102.372% 102.315% no-repeat;
                        }

                        &:nth-child(even) {
                            background: linear-gradient(0deg, #5B5B5B 0%, #5B5B5B 100%), lightgray -7.081px -10px / 102.372% 102.315% no-repeat;
                            border: none;
                        }

                        &:last-child {
                            border: none;
                        }
                    }
                }

            }

            @include device(s) {
                .venue-hire-room-hero-img-wrapper {
                    height: 700px;

                    .enquire-now-wrapper {
                        padding: 36px 40px;

                        .enquire-now-head {
                            padding: 0 0 4px;
                            font-size: 38px;
                            letter-spacing: -0.38px;
                        }

                        .enquire-now-content {
                            font-size: 15px;
                            letter-spacing: 1.5px;
                        }
                    }
                }

                .venue-hire-room-more-img-container {
                    grid-template-columns: 1fr 1fr;

                    .venue-hire-room-more-img-wrapper {
                        height: 410px;

                        &:nth-child(odd) {
                            background-color: $on-primary-light;
                        }

                        &:nth-child(even) {
                            background-color: $on-primary;
                            border: none;
                            @include dashedBorder(l, b);
                        }

                        &:last-child {
                            @include dashedBorder(t, r);
                        }
                    }
                }
            }

            @include device(m) {
                .venue-hire-room-hero-img-wrapper {
                    height: 750px;

                    .enquire-now-wrapper {
                        padding: 36px 40px;

                        .enquire-now-head {
                            padding: 0 0 6px;
                            font-size: 40px;
                            letter-spacing: -0.40px;
                        }

                        .enquire-now-content {
                            font-size: 16px;
                            letter-spacing: 1.6px;
                        }
                    }
                }

                .venue-hire-room-more-img-container {
                    grid-template-columns: 1fr 1fr;

                    .venue-hire-room-more-img-wrapper {
                        height: 432px;

                        &:nth-child(odd) {
                            background-color: $on-primary-light;
                        }

                        &:nth-child(even) {
                            background-color: $on-primary;
                            border: none;
                            @include dashedBorder(l, b);
                        }

                        &:last-child {
                            @include dashedBorder(t, r);
                        }
                    }
                }
            }

            @include device(l) {
                .venue-hire-room-hero-img-wrapper {
                    height: 750px;

                    .enquire-now-wrapper {
                        padding: 36px 40px;

                        .enquire-now-head {
                            padding: 0 0 8px;
                            font-size: 42px;
                            letter-spacing: -0.42px;
                        }

                        .enquire-now-content {
                            font-size: 17px;
                            letter-spacing: 1.7px;
                        }
                    }
                }

                .venue-hire-room-more-img-container {
                    .venue-hire-room-more-img-wrapper {
                        height: 454px;

                        &:nth-child(odd) {
                            background-color: $on-primary-light;
                        }

                        &:nth-child(even) {
                            background-color: $on-primary;
                        }
                    }
                }
            }
        }
    }
}

@include device(xs) {
    .venue-hire-container {
        .venue-hire-head-wrapper {
            padding: 68px 22px 53px;
        }
    }
}

@include device(s) {
    .venue-hire-container {
        .venue-hire-head-wrapper {
            padding: 57px 40px 66px;
        }
    }

}

@include device(m) {
    .venue-hire-container {
        .venue-hire-head-wrapper {
            padding: 74px 60px 73px;
        }
    }


}

@include device(l) {
    .venue-hire-container {
        .venue-hire-head-wrapper {
            padding: 91px 80px 79px;
        }
    }
}