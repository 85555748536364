@import 'src/styles/_variables.scss';

.account-sign-up-container {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    background-color: $primary;

    .account-sign-up-wrapper {
        margin: 0 auto;
        min-width: 320px;
        width: 100%;
        max-width: 480px;
        height: 100%;

        .account-sign-up-form {
            padding: 80px 20px 40px;

            .account-sign-up-form-header {
                margin: 0 0 50px;

                .account-sign-up-form-header-title {
                    margin: 0 0 20px;
                    color: $on-primary-light;
                    font-family: $bff-itc-fgs-demi;
                    font-size: 45px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 1;
                    /* 83%; 37.35px */
                    letter-spacing: -0.45px;
                    text-transform: uppercase;
                }

                .account-sign-up-form-header-info {
                    color: $on-primary-light;
                    font-family: $bff-peregrin-denton-light;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 1; /*120%; 19.2px */
                    letter-spacing: 0.8px;
                }
            }

            .account-sign-up-form-body {
                margin: 0 0 35px;
                display: grid;
                gap: 16px 0;

                .account-sign-up-form-body-item {
                    width: 100%;
                    height: 40px;

                    .account-sign-up-form-body-item-input {
                        padding: 12px 20px 8px;
                        width: 100%;
                        height: 100%;
                        border-radius: 12px;
                        border: 3px solid $border-light-black;
                        outline: 0;
                        background: $secondary;
                        color: $on-secondary;
                        font-family: $bff-itc-fgs-book;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 1;
                        /* 83%; 14.94px */
                        letter-spacing: 1.8px;
                        &::placeholder, &::-webkit-input-placeholder {
                            text-transform: uppercase;
                        }

                        &[type="password"]:not(:placeholder-shown) {
                            letter-spacing: 5px;
                        }
                    }
                }

                .account-sign-up-form-body-item-err-msg {
                    margin: 6px 0 0 8px;
                    color: $error;
                    opacity: 0.7;
                    font-family: $bff-peregrin-denton-light;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 1;
                    letter-spacing: 0.24px;
                }
            }

            .account-sign-up-form-note {
                color: $merky-fc-gray;
                font-family: $bff-peregrin-denton-light;
                font-size: 12px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
                letter-spacing: 0.6px;
            }
        }

        .account-sign-up-tcs-container {
            padding: 55px 20px;
            @include dashedBorder(t,b);

            .account-sign-up-tcs-wrapper {
                display: grid;
                grid-template-columns: min-content 1fr;
                gap: 0 10px;

                .account-sign-up-tcs-checkbox-wrapper {
                    width: 34px;
                    height: 34px;
                    cursor: pointer;

                    .account-sign-up-tcs-checkbox-input {
                        display: none;
                    }

                    .account-sign-up-tcs-checkbox-icon {
                        width: 100%;
                        height: 100%;
                        cursor: pointer;
                    }
                }

                .account-sign-up-tcs-label {
                    margin-bottom: -6px;
                    align-self: center;
                    color: $on-primary-light;
                    font-family: $bff-itc-fgs-book;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 1;
                    letter-spacing: 1.8px;
                    text-transform: uppercase;
                    user-select: none;
                    -webkit-user-select: none;

                    .account-sign-up-tcs-link {
                        text-decoration-line: underline;
                        color: $link;
                    }
                }
            }

            .account-sign-up-form-body-item-err-msg {
                margin: 8px 0 0 0px;
                color: $error;
                opacity: 0.7;
                font-family: $bff-peregrin-denton-light;
                font-size: 14px;
                font-style: normal;
                font-weight: 300;
                line-height: 1;
                letter-spacing: 0.24px;
            }
        }

        .account-sign-up-action-wrapper {
            padding: 55px 20px 100px;
            user-select: none;
            -webkit-user-select: none;

            .account-sign-up-action {
                width: 100%;
                height: 45px;

                .account-sign-up-action-btn {
                    padding: 14px 26px;
                    width: 100%;
                    height: 100%;
                    outline: 0;
                    border-radius: 16px;
                    border: 3px solid $border-light-black;
                    font-family: $bff-itc-fgs-book;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 1;
                    letter-spacing: 1.8px;
                    text-transform: uppercase;
                    color: $on-secondary-dark;
                    background: $secondary;
                    cursor: pointer;

                    &.in-valid {
                        opacity: 0.6;
                        cursor: not-allowed;
                    }
                }
            }
        }

        @include device(xs, s, m) {
            .account-sign-up-form {
                .account-sign-up-form-header {
                    margin: 0 0 50px;

                    .account-sign-up-form-header-title {
                        font-size: 35px;
                        letter-spacing: -0.35px;
                    }

                    .account-sign-up-form-header-info {
                        font-size: 12px;
                        line-height: normal;
                        letter-spacing: 0.6px;
                    }
                }

                .account-sign-up-form-body {
                    .account-sign-up-form-body-item {
                        .account-sign-up-form-body-item-input {
                            font-size: 16px;
                            letter-spacing: 0.32px;
                        }
                    }
                }
            }
        }
    }
}