@import 'src/styles/variables';


.terms-and-conditions-container {
  padding: 100px 100px 120px;
  width: 100%;
  height: auto;
  background-color: $primary;

  .terms-and-conditions-head {
    padding: 0 0 86px;

    .terms-and-conditions-head-wrapper {
      width: 100%;

      .terms-and-conditions-head-title {
        margin: 0 0 32px;
        color: $on-primary;
        font-family: $bff-itc-fgs-demi;
        font-size: 70px;
        font-style: normal;
        font-weight: 600;
        line-height: 1;
        /* 83%; 58.1px */
        letter-spacing: -0.7px;
        text-transform: capitalize;
      }

      .terms-and-conditions-head-description {
        color: $on-primary-light;
        font-family: $bff-itc-fgs-book;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        // line-height: 83%; /* 14.94px */
        letter-spacing: 1.8px;
        text-transform: uppercase;
      }
    }
  }

  .terms-and-conditions-description {
    color: $on-primary-light;
    font-family: $bff-peregrin-denton-light;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    // line-height: 120%; /* 19.2px */
    letter-spacing: 0.8px;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6{
      font-family: $bff-itc-fgs-book;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p{
      margin: revert !important;
    }
    ol, ul {
      padding: revert !important;
    }
  }

  a {
    color: $link;
  }



  @include device(xs) {
    padding: 60px 20px 80px;

    .terms-and-conditions-head {
      padding: 0 0 60px;

      .terms-and-conditions-head-wrapper {
        .terms-and-conditions-head-title {
          font-size: 35px;
          /* 29.05px */
          letter-spacing: 0.35px;
        }

        .terms-and-conditions-head-description {
          font-size: 15px;
          letter-spacing: 1.5px;
        }
      }
    }

    .terms-and-conditions-description {
      font-size: 12px;
      line-height: normal;
      letter-spacing: 0.6px;
    }
  }

  @include device(s) {
    padding: 70px 40px 80px;

    .terms-and-conditions-head {
      padding: 0 0 66px;

      .terms-and-conditions-head-wrapper {
        .terms-and-conditions-head-title {
          font-size: 43px;
          /* 29.05px */
          letter-spacing: 0.25px;
        }

        .terms-and-conditions-head-description {
          font-size: 15px;
          letter-spacing: 1.5px;
        }
      }
    }

    .terms-and-conditions-description {
      font-size: 12px;
      line-height: normal;
      letter-spacing: 0.6px;
    }
  }

  @include device(m) {
    padding: 80px 60px 100px;

    .terms-and-conditions-head {
      padding: 0 0 73px;

      .terms-and-conditions-head-wrapper {
        .terms-and-conditions-head-title {
          font-size: 52px;
          /* 29.05px */
          letter-spacing: 0.15px;
        }

        .terms-and-conditions-head-description {
          font-size: 16px;
          letter-spacing: 1.5px;
        }
      }
    }

    .terms-and-conditions-description {
      font-size: 14px;
      line-height: normal;
      letter-spacing: 0.6px;
    }
  }

  @include device(l) {
    padding: 90px 80px 110px;

    .terms-and-conditions-head {
      padding: 0 0 79px;

      .terms-and-conditions-head-wrapper {
        .terms-and-conditions-head-title {
          font-size: 60px;
          /* 29.05px */
          letter-spacing: 0.05px;
        }

        .terms-and-conditions-head-description {
          font-size: 17px;
          letter-spacing: 1.5px;
        }
      }
    }

    .terms-and-conditions-description {
      font-size: 14px;
      line-height: normal;
      letter-spacing: 0.6px;
    }
  }
}

.terms-and-conditions-loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .terms-and-conditions-loader-img {
    width: 80px;
    height: 80px;
  }
}