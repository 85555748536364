@import '_reset';

@import '_variables';


html,
body {
    width: 100%;
    min-height: 100vh;
    height: auto;
    position: relative;
    font-family: $base-font-family-ad;
    font-size: 16px;
    line-height: $base-line-height;
    background-color: $primary;
    color: $on-primary-light;
    // background-image: url('../assets/images/app-background.jpg');
    // background-position: center;
    // background-size: contain;
    
    @include device(xs, s) {
        font-size: 14px;
    }
    @include device(m, l, xl) {
        font-size: 16px;
    }
    @include device(xxl, xxxl) {
        font-size: 18px;
    }
}

::selection {
    color: $on-selection;
    background: $selection;
}

*,
*::before,
*::after {
    // font: inherit;
	position: relative;
  	box-sizing: border-box;
}