@import 'src/styles/_variables.scss';

.booking-container {
    margin: 0;
    padding: 0;
    width: 100%;
    max-width: 1428px;
    height: auto;
    background: $primary;

    .booking-hero-wrapper {
        position: relative;
        width: 100%;
        // max-width: 1428px;
        height: 370px;
        overflow: hidden;

        .booking-hero-img {
            width: 100%;
            height: 100%;
            overflow: hidden;
            object-fit: cover;
            object-position: center;
        }

        @include device(xs) {
            height: 150px;
        }

        @include device(s) {
            height: 205px;
        }

        @include device(m) {
            height: 260px;
        }

        @include device(l) {
            height: 315px;
        }
    }

    .booking-selection-container {
        padding: 100px 100px 0; //60px;
        @include dashedBorder(b);

        &.border-0 {
            border: 0;
        }

        .booking-selection-wrapper {
            margin: 0 0 110px;

            .booking-selection-types-title {
                margin: 0 0 20px;
                color: $on-primary;
                font-family: $bff-itc-fgs-book;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 1;
                /* 14.94px */
                letter-spacing: 1.8px;
                text-transform: uppercase;
            }

            .booking-selection-types-wrapper {
                padding: 0 0 10px;
                display: flex;
                gap: 50px;
                border-bottom: 2px solid $border-gray;

                .booking-selection-type {
                    color: $on-primary;
                    text-align: center;
                    font-family: $bff-itc-fgs-demi;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 1;
                    /* 14.94px */
                    letter-spacing: 1.8px;
                    text-transform: uppercase;
                    cursor: pointer;

                    &.selected {
                        color: $link;
                    }
                }
            }
        }

        .booking-title-desc-wrapper {
            padding: 0 0 90px;
            // background: bisque;

            .booking-title {
                margin: 0 0 40px;
                max-width: 786px;
                color: $on-primary;
                font-family: $bff-itc-fgs-demi;
                font-size: 70px;
                font-style: normal;
                font-weight: 600;
                line-height: 1;
                /* 58.1px */
                letter-spacing: -0.7px;
                text-transform: uppercase;
            }

            .booking-description {
                max-width: 786px;
                color: $on-primary-light;
                font-family: $bff-peregrin-denton-light;
                font-size: 16px;
                font-style: normal;
                font-weight: 300;
                line-height: 120%;
                /* 19.2px */
                letter-spacing: 0.8px;

                .venue-enquire-link {
                    color: $link;
                    font-family: $bff-peregrin-denton-light;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 120%;
                    /* 19.2px */
                    letter-spacing: 0.8px;
                    text-transform: uppercase;
                }
            }
        }

        .crown-still {
            height: 425px;
            position: absolute;
            bottom: -70px;
            right: -100px;
            z-index: 0;
            overflow: hidden;
            user-select: none;
            -webkit-user-select: none;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .booking-pitch-filter-container {
            padding: 0px 0 0px;
            display: none;

            &.show {
                display: block;
            }

            .booking-pitch-filter-wrapper {
                margin: 0 0 60px;
                max-height: 33px;
                display: none;
                grid-template-columns: 260px repeat(3, 1fr);
                user-select: none;
                -webkit-user-select: none;

                .booking-pitch-filter-title {
                    padding: 10px 0 0;
                    color: $on-primary-light;
                    font-family: $bff-itc-fgs-book;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 83%;
                    /* 14.94px */
                    letter-spacing: 1.8px;
                    text-transform: uppercase;
                }

                .booking-pitch-filter-item-wrapper {
                    position: relative;
                    border: 2px solid $border-light-black-60;
                    background-color: $secondary;

                    &:nth-child(3) {
                        border-width: 2px 0;
                    }

                    .booking-pitch-filter-item-label {
                        padding: 7px 0 3px;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: $on-secondary-dark;
                        text-align: center;
                        font-family: $bff-itc-fgs-book;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 1;
                        /* 14.94px */
                        letter-spacing: 1.8px;
                        text-transform: uppercase;
                        cursor: pointer;
                    }

                    .booking-pitch-filter-item {
                        display: none;

                        &:checked+label .booking-pitch-filter-item-label {
                            background-color: $tertiary;
                            color: $on-tertiary;
                        }
                    }
                }

                @include device(l, xl, xxl, xxxl) {
                    display: grid;
                }
            }

            .booking-pitch-filter-m-container {

                .booking-pitch-filter-m-btn-wrapper {
                    padding: 0 0 60px;
                    text-align: center;

                    .booking-pitch-filter-m-btn {
                        padding: 4px 0 0;
                        height: 45px;
                        max-width: 315px;
                        width: 100%;
                        border-radius: 16px;
                        background-color: $secondary;
                        color: $on-secondary;
                        border: 2px solid $border-light-black-80;
                        text-align: center;
                        font-family: $bff-itc-fgs-book;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 1;
                        letter-spacing: 2.4px;
                        text-transform: uppercase;
                        cursor: pointer;
                        z-index: 1;
                    }
                }

                @include device(xs, s) {
                    .booking-pitch-filter-m-btn-wrapper {
                        padding: 0;
                    }
                }

                @include device(l, xl, xxl, xxxl) {
                    display: none;
                }
            }
        }

        @include device(xs) {
            padding: 60px 20px 0; //20px;
            border: 0;

            .booking-selection-wrapper {
                margin: 0 0 50px;

                .booking-selection-types-title {
                    font-size: 15px;
                    letter-spacing: 1.5px;
                }

                .booking-selection-types-wrapper {
                    gap: 35px;
                    justify-content: space-between;

                    .booking-selection-type {
                        font-size: 13px;
                    }
                }
            }

            .booking-title-desc-wrapper {
                .booking-title {
                    margin: 0 0 15px;
                    font-size: 36px;
                    letter-spacing: -0.36px;
                }

                .booking-description {
                    font-size: 12px;
                    letter-spacing: 0.6px;

                    .venue-enquire-link {
                        font-size: 12px;
                        letter-spacing: 0.6px;
                    }
                }
            }

            .crown-still {
                height: 205px;
                bottom: -120px;
                right: -20px;
            }
        }

        @include device(s) {
            padding: 70px 40px 0; //20px;
            border: 0;

            .booking-selection-wrapper {
                margin: 0 0 65px;

                .booking-selection-types-title {
                    font-size: 15px;
                    letter-spacing: 1.5px;
                }

                .booking-selection-types-wrapper {
                    .booking-selection-type {
                        font-size: 15 px;
                    }
                }
            }

            .booking-title-desc-wrapper {
                .booking-title {
                    margin: 0 0 25px;
                    font-size: 47px;
                    letter-spacing: -0.47px;
                }

                .booking-description {
                    font-size: 12px;
                    letter-spacing: 0.6px;

                    .venue-enquire-link {
                        font-size: 12px;
                        letter-spacing: 0.6px;
                    }
                }
            }

            .crown-still {
                height: 250px;
                bottom: -140px;
                right: -40px;
            }
        }

        @include device(m) {
            padding: 80px 60px 0; //20px;

            .booking-selection-wrapper {
                margin: 0 0 80px;

                .booking-selection-types-title {
                    font-size: 16px;
                    letter-spacing: 1.6px;
                }

                .booking-selection-types-wrapper {
                    .booking-selection-type {
                        font-size: 16px;
                    }
                }
            }

            .booking-title-desc-wrapper {
                .booking-title {
                    margin: 0 0 30px;
                    font-size: 58px;
                    letter-spacing: -0.58px;
                }

                .booking-description {
                    max-width: 500px;
                    // font-size: 16px;
                    // letter-spacing: 0.14px;
                }
            }

            .crown-still {
                height: 375px;
                bottom: -70px;
                right: -60px;
            }
        }

        @media only screen and (min-width: 992.01px) and (max-width: 1150px) {
            .booking-title-desc-wrapper {
                .booking-title {
                    max-width: 650px;
                }

                .booking-description {
                    max-width: 650px;
                }
            }
        }

        @include device(l) {
            padding: 90px 80px 0; //20px;

            .booking-selection-wrapper {
                margin: 0 0 95px;

                .booking-selection-types-title {
                    font-size: 17px;
                    letter-spacing: 1.7px;
                }

                .booking-selection-types-wrapper {
                    .booking-selection-type {
                        font-size: 17px;
                    }
                }
            }

            .booking-title-desc-wrapper {
                .booking-title {
                    margin: 0 0 35px;
                    font-size: 68px;
                    letter-spacing: -0.68px;
                }

                // .booking-description {
                //     font-size: 18px;
                //     letter-spacing: 0.09px;
                // }
            }

            .crown-still {
                height: 375px;
                bottom: -60px;
                right: -80px;
            }
        }
    }

    .booking-checkout-m-container {
        .booking-checkout-m-btn-wrapper {
            text-align: center;
            margin: 14px 0 0;

            &.adjust-padding {
                padding: 40px 0 0;
            }

            .booking-checkout-m-btn {
                padding: 4px 0 0;
                height: 45px;
                max-width: 315px;
                width: 100%;
                border-radius: 16px;
                background-color: $secondary;
                color: $on-secondary;
                border: 2px solid $border-light-black-80;
                text-align: center;
                font-family: $bff-itc-fgs-book;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 1;
                letter-spacing: 2.4px;
                text-transform: uppercase;
                cursor: pointer;
                z-index: 1;
            }
        }
    }

    .booking-section-container {
        padding: 55px 100px 100px 60px;

        &.venue-room-tab {
            padding: 0 !important;
        }

        .booking-section-wrapper {
            display: grid;
            grid-template-columns: 1fr max-content;
            background-color: black;

            .booking-slots-container {
                .booking-slots-wrapper {
                    display: grid;
                    grid-template-columns: 30px 1fr 30px;
                    gap: 0 10px;

                    .booking-slot-previous-btn,
                    .booking-slot-next-btn {
                        width: 30px;
                        height: 30px;
                        user-select: none;
                        -webkit-user-select: none;
                        cursor: pointer;

                        &.disabled {
                            cursor: not-allowed;
                            opacity: 0.6;
                        }
                    }

                    .booking-slots {
                        // margin: 0 auto;
                        display: grid;
                        grid-template-columns: repeat(3, 1fr);
                        gap: 10px;

                        &.size-1 {
                            grid-template-columns: repeat(1, 1fr);
                            justify-self: center;
                        }

                        &.size-2 {
                            grid-template-columns: repeat(2, 1fr);
                            justify-self: center;
                        }

                        .booking-slot-wrapper {
                            .booking-slot-title {
                                padding: 8px 0 4px;
                                margin: 0 0 20px;
                                color: $on-secondary-dark;
                                text-align: center;
                                font-family: $bff-itc-fgs-book;
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 1;
                                /* 14.94px */
                                letter-spacing: 1.8px;
                                text-transform: uppercase;
                                user-select: none;
                                -webkit-user-select: none;
                            }

                            .booking-slot-list {
                                max-width: 300px;
                                display: grid;
                                gap: 10px 0;
                                grid-template-columns: 1fr;

                                .booking-slot-details {
                                    position: relative;
                                    padding: 30px 20px;
                                    min-width: 254px;
                                    width: 100%;
                                    aspect-ratio: 1/0.85;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: flex-end;
                                    gap: 20px 0;
                                    text-align: center;
                                    background-color: $secondary;
                                    border: 2px solid $border-light-black-80;

                                    .booking-slot-detail-title {
                                        color: $on-secondary;
                                        font-family: $bff-itc-fgs-demi;
                                        font-size: 45px;
                                        font-style: normal;
                                        font-weight: 600;
                                        line-height: 1;
                                        /* 37.35px */
                                        letter-spacing: -0.45px;
                                        text-transform: uppercase;
                                    }

                                    .booking-slot-detail-input-wrapper {
                                        display: inline-flex;
                                        justify-content: center;
                                        align-items: center;
                                        color: $on-secondary;
                                        font-family: $bff-itc-fgs-book;
                                        font-size: 18px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: 1;
                                        /* 14.94px */
                                        letter-spacing: 1.8px;
                                        text-transform: uppercase;

                                        .booking-slot-detail-input-field,
                                        input[type=number] {
                                            margin: -5px 0 0;
                                            padding: 0 0 0 5px;
                                            width: 38px;
                                            height: 23px;
                                            outline: none;
                                            border: 0;

                                            // -moz-appearance: textfield;
                                            // /* Firefox */
                                            // &::-webkit-outer-spin-button,
                                            // &::-webkit-inner-spin-button {
                                            //     -webkit-appearance: none;
                                            //     margin: 0;
                                            // }
                                        }
                                    }

                                    .booking-slot-detail-add-link {
                                        color: $link;
                                        font-family: $bff-peregrin-denton-light;
                                        font-size: 12px;
                                        font-style: normal;
                                        font-weight: 300;
                                        line-height: normal;
                                        letter-spacing: 0.6px;
                                        text-decoration-line: underline;
                                        text-transform: uppercase;
                                        cursor: pointer;

                                        &:hover {
                                            ~ {

                                                .sign-in-popup,
                                                .sign-in-popup-arrow {
                                                    display: inline-block;
                                                }
                                            }
                                        }
                                    }

                                    .sign-in-popup {
                                        padding: 12px 18px 12px;
                                        width: 86%;
                                        display: none;
                                        position: absolute;
                                        left: 50%;
                                        bottom: 63px;
                                        transform: translate(-50%, 0px);
                                        font-family: $bff-peregrin-denton-light;
                                        font-size: 14px;
                                        font-style: normal;
                                        letter-spacing: 0.8px;
                                        color: $on-primary-light;
                                        background-color: $primary;
                                        border: 2px solid $border-light-black;
                                        border-radius: 8px;
                                    }

                                    .sign-in-popup-arrow {
                                        width: 16px;
                                        height: 16px;
                                        display: none;
                                        position: absolute;
                                        bottom: 50px;
                                        left: 50%;
                                        transform: rotate(45deg) translate(-50%, 0px);
                                        color: $on-primary-light;
                                        background-color: $primary;
                                        border: 2px solid $border-light-black;
                                        border-left-width: 0;
                                        border-top-width: 0;
                                    }

                                    &.booked {
                                        background-color: #0B0B0B;
                                        user-select: none;
                                        -webkit-user-select: none;

                                        .booking-slot-detail-title {
                                            color: #FFFFFF4D; // rgba(255, 255, 255, 0.30);
                                        }

                                        .booking-slot-detail-input-wrapper {
                                            color: #FFFFFF4D; // rgba(255, 255, 255, 0.30);

                                            .booking-slot-detail-input-field,
                                            input[type=number] {
                                                background-color: #FFFFFF4D;
                                                color: $merky-fc-light-black;
                                                pointer-events: none;
                                                -moz-appearance: textfield;

                                                /* Firefox */
                                                &::-webkit-outer-spin-button,
                                                &::-webkit-inner-spin-button {
                                                    -webkit-appearance: none;
                                                    margin: 0;
                                                }
                                            }
                                        }

                                        .booking-slot-detail-add-link {
                                            cursor: initial;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .booking-basket-container {
                padding: 50px 0 0 80px;
                width: 100%;
                height: 100%;

                .booking-basket-wrapper {
                    width: 315px;
                    height: max-content;
                    position: sticky;
                    top: 80px;
                    background-color: $secondary;

                    .booking-basket-title {
                        background-color: $primary;
                        padding: 10px 15px 4px;
                        color: $on-primary;
                        font-family: $bff-itc-fgs-demi;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 1;
                        /* 14.94px */
                        letter-spacing: 1.8px;
                        text-transform: uppercase;
                        border: 2px solid $border-light-black;
                    }

                    .booking-basket-items {
                        max-height: 60vh;
                        overflow: hidden auto;
                        border-left: 2px solid $border-light-black;
                        border-right: 2px solid $border-light-black;

                        /* width */
                        &::-webkit-scrollbar {
                            width: 8px;
                        }

                        /* Track */
                        &::-webkit-scrollbar-track {
                            background-color: transparent;
                        }

                        /* Handle */
                        &::-webkit-scrollbar-thumb {
                            background: #A2A4A4;
                            border-radius: 3px;
                        }

                        /* Handle on hover */
                        &::-webkit-scrollbar-thumb:hover {
                            background: #B1B2B2;
                        }

                        .booking-basket-item {
                            padding: 18px 15px 14px;
                            border-bottom: 2px solid $border-light-black;

                            .booking-basket-item-detail {
                                padding: 0 0 1px;
                                color: $on-secondary;
                                font-family: $bff-itc-fgs-book;
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                /* 14.94px */
                                letter-spacing: 1.8px;
                                text-transform: uppercase;
                            }

                            .booking-basket-item-action-wrapper {
                                padding: 20px 0 0;
                                display: flex;
                                justify-content: space-between;

                                .booking-basket-item-price {
                                    color: $on-secondary;
                                    font-family: $bff-itc-fgs-demi;
                                    font-size: 18px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: 83%;
                                    /* 14.94px */
                                    letter-spacing: 1.8px;
                                    text-transform: uppercase;
                                }

                                .booking-basket-item-remove {
                                    color: $on-secondary;
                                    font-family: $bff-itc-fgs-demi;
                                    font-size: 13px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: 83%;
                                    /* 10.79px */
                                    letter-spacing: 1.3px;
                                    text-transform: uppercase;
                                    text-decoration-line: underline;
                                    cursor: pointer;

                                    &:hover {
                                        color: $merky-fc-yellow;
                                    }
                                }
                            }

                            .booking-basket-empty-box {
                                width: 100%;
                                height: 50px;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                .booking-basket-empty-box-msg {
                                    color: $on-secondary-dark;
                                    font-family: $bff-peregrin-denton-light;
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                    letter-spacing: 0.6px;
                                    text-transform: capitalize;
                                }
                            }
                        }
                    }

                    .booking-basket-footer-wrapper {
                        padding: 15px;
                        width: 100%;
                        height: fit-content;
                        background-color: $tertiary;

                        .booking-basket-totals {
                            // margin: 0 0 14px;

                            .booking-basket-total-title {
                                margin: 0 0 20px;
                                color: $on-tertiary;
                                font-family: $bff-itc-fgs-book;
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 1;
                                /* 14.94px */
                                letter-spacing: 1.8px;
                                text-transform: uppercase;
                            }

                            .booking-basket-total-price {
                                color: $on-tertiary;
                                font-family: $bff-itc-fgs-demi;
                                font-size: 45px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 1;
                                /* 37.35px */
                                letter-spacing: -0.45px;
                            }
                        }

                        .booking-basket-divider {
                            width: 100%;
                            margin: 0 0 15px;
                            border-bottom: 1px dashed $on-tertiary;
                        }

                        .booking-basket-action-wrapper {
                            width: 100%;
                            height: 33px;

                            .booking-basket-action-btn {
                                padding: 8px 0 4px;
                                width: inherit;
                                height: inherit;
                                border-radius: 12px;
                                background-color: $secondary;
                                border: 2px solid rgba(179, 180, 180, 0.33);
                                cursor: pointer;

                                .booking-basket-action-btn-text {
                                    color: $on-secondary;
                                    text-align: center;
                                    font-family: $bff-itc-fgs-book;
                                    font-size: 18px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 1;
                                    /* 14.94px */
                                    letter-spacing: 1.8px;
                                    text-transform: uppercase;
                                }

                                &.disabled {
                                    cursor: not-allowed;
                                    opacity: 0.85;
                                }
                            }
                        }
                    }

                    @include device(xs, s) {
                        .booking-basket-title {
                            padding: 11px 15px 5px;
                            font-size: 16px;
                            letter-spacing: 0.16px;
                        }

                        .booking-basket-items .booking-basket-item {
                            .booking-basket-item-detail {
                                font-size: 15px;
                                letter-spacing: 1.5px;
                            }

                            .booking-basket-item-action-wrapper .booking-basket-item-price {
                                font-size: 16px;
                                letter-spacing: 0.16px;
                            }

                            .booking-basket-empty-box .booking-basket-empty-box-msg {
                                font-size: 14px;
                                letter-spacing: 0.4px;
                            }
                        }

                        .booking-basket-footer-wrapper {
                            .booking-basket-totals {
                                .booking-basket-total-title {
                                    font-size: 16px;
                                    letter-spacing: 2.4px;
                                }

                                .booking-basket-total-price {
                                    font-size: 36px;
                                    letter-spacing: -0.36px;
                                }
                            }

                            .booking-basket-action-wrapper .booking-basket-action-btn .booking-basket-action-btn-text {
                                font-size: 15px;
                            }
                        }
                    }

                    @include device(m) {
                        top: 55px;
                    }
                }
            }

            /** for mobile screen */
            &.m-view {
                .booking-section-wrapper {
                    grid-template-columns: 1fr;
                }

                .booking-basket-container {
                    padding: 0;
                    display: none;
                    position: fixed;
                    top: 0;
                    left: 0;
                    z-index: 99;
                    background: rgba(0, 0, 0, 0.9);

                    &.show {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .booking-basket-wrapper {
                        width: 340px;
                        top: unset;

                        .booking-basket-close-btn-wrap {
                            position: absolute;
                            top: 8px;
                            right: 8px;
                            cursor: pointer;

                            svg {
                                display: block;
                                width: 18px;
                                height: 18px;
                            }

                            &:hover {
                                svg path {
                                    fill: $merky-fc-yellow;
                                }
                            }
                        }
                    }
                }
            }
        }

        .music-studio-form-wrap {
            margin: -70px auto 0;
            max-width: 400px;
            width: 100%;
            display: grid;
            gap: 16px 0;

            .music-studio-form-item {
                width: 100%;
                height: 40px;

                .music-studio-form-item-input {
                    padding: 12px 16px 8px;
                    width: 100%;
                    height: 100%;
                    border-radius: 12px;
                    border: 3px solid $border-light-black;
                    outline: 0;
                    background: $secondary;
                    color: $on-secondary;
                    font-family: $bff-itc-fgs-book;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 1;
                    /* 83%; 14.94px */
                    letter-spacing: 1.8px;
                    text-transform: uppercase;

                    &.date-time {
                        color: #757575;
                    }

                    &.date-time-icon {
                        &::-webkit-calendar-picker-indicator {
                            filter: invert(.4);
                        }
                    }
                }

                &.multiline {
                    min-height: 150px;

                    .music-studio-form-item-input,
                    textarea {
                        resize: none;
                    }
                }
            }

            .music-studio-form-item-err-msg {
                margin: 6px 0 0 8px;
                color: $error;
                opacity: 0.7;
                font-family: $bff-peregrin-denton-light;
                font-size: 14px;
                font-style: normal;
                font-weight: 300;
                line-height: 1;
                letter-spacing: 0.24px;
            }

            .music-studio-form-action-wrapper {
                user-select: none;
                -webkit-user-select: none;

                .music-studio-form-action-btn {
                    padding: 14px 26px;
                    width: 100%;
                    height: 45px;
                    font-family: $bff-itc-fgs-book;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 1;
                    letter-spacing: 1.8px;
                    text-transform: uppercase;
                    background: $secondary;
                    color: $on-secondary-dark;
                    border-radius: 16px;
                    outline: 0;
                    border: 3px solid $border-light-black;
                    cursor: pointer;
                }
            }
        }

        .music-studio-form-submitted-ctr {
            width: 100%;
            height: auto;
            position: fixed;
            inset: 0;
            background: rgba(0, 0, 0, 0.75);
            z-index: 99;

            .music-studio-form-submitted-wrapper {
                position: fixed;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                display: grid;
                grid-template-columns: 1fr 1fr;
                width: 100%;
                max-width: 930px;
                height: 100%;
                max-height: 620px;

                .music-studio-form-submitted-modal-close-btn {
                    position: absolute;
                    top: 12px;
                    right: 12px;
                    width: 24px;
                    height: 24px;
                    z-index: 1;
                    user-select: none;
                    -webkit-user-select: none;

                    &:hover {
                        cursor: pointer;

                        svg path {
                            fill: $link;
                        }
                    }
                }

                .music-studio-form-submitted-poster-wrapper {
                    width: 100%;
                    height: 100%;
                    overflow: hidden;

                    .music-studio-form-submitted-poster {
                        display: block;
                        height: 100%;
                        width: 100%;
                        content: url('../../assets/images/request-submit.png');
                        object-fit: cover;
                        object-position: top center;
                    }
                }

                .music-studio-form-submitted-section-wrapper {
                    padding: 30px 30px;
                    background-color: $modal-primary;

                    .music-studio-form-submitted-section {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        height: 100%;
                        justify-content: space-between;

                        .music-studio-form-submitted-section-title {
                            margin: 15px 15px 20px 0;
                            color: $on-modal-primary;
                            font-family: $bff-itc-fgs-demi;
                            font-size: 70px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 83%;
                            /* 58.1px */
                            letter-spacing: -0.7px;
                            text-transform: uppercase;
                        }

                        .music-studio-form-submitted-section-description {
                            padding: 4px 0 0;
                            color: $on-modal-primary;
                            font-family: $bff-itc-fgs-book;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 83%;
                            /* 14.94px */
                            letter-spacing: 1.8px;
                            text-transform: uppercase;

                            &.mb {
                                margin-bottom: 20px;
                            }
                        }

                        .music-studio-form-submitted-section-action {
                            width: 100%;
                            height: auto;

                            .music-studio-form-submitted-section-submit-btn {
                                padding: 4px 0 0;
                                width: 100%;
                                height: 45px;
                                background: $secondary;
                                color: $on-secondary-dark;
                                font-family: $bff-itc-fgs-book;
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 83%;
                                /* 14.94px */
                                letter-spacing: 1.8px;
                                text-transform: uppercase;
                                border: 3px solid $border-gray-33;
                                border-radius: 16px;
                                outline: 0;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }

            @include device(xs, s, m) {
                .music-studio-form-submitted-wrapper {
                    grid-template-columns: unset;
                    grid-template-rows: max-content 1fr;
                    max-width: 390px;
                    max-height: 745px;

                    .music-studio-form-submitted-poster-wrapper {
                        .music-studio-form-submitted-poster {
                            content: url('../../assets/images/request-submit-m.png');
                        }
                    }

                    .music-studio-form-submitted-section-wrapper {
                        padding: 25px 16px;

                        .music-studio-form-submitted-section {
                            .music-studio-form-submitted-section-title {
                                font-size: 45px;
                                letter-spacing: -0.45px;
                            }
                        }
                    }
                }
            }
        }


        @media screen and (max-width: 385px) {
            padding: 40px 10px 60px 10px !important;
        }

        @include device(xs) {
            padding: 40px 20px 60px 20px;

            .booking-section-wrapper .booking-slots-container {
                .booking-slots-wrapper {
                    .booking-slots {
                        .booking-slot-wrapper {
                            .booking-slot-title {
                                margin: 0 0 50px;
                            }
                        }
                    }
                }
            }
        }

        @include device(s) {
            padding: 44px 40px 70px 30px;

            .booking-section-wrapper .booking-slots-container {
                .booking-slots-wrapper {
                    .booking-slots {
                        .booking-slot-wrapper {
                            .booking-slot-title {
                                margin: 0 0 50px;
                            }
                        }
                    }
                }
            }
        }

        @include device(m) {
            padding: 48px 60px 80px 40px;
        }

        // for horizontally scroll
        @media only screen and (min-width: 769px) and (max-width: 840px) {
            padding: 48px 40px 80px 30px !important;

            .booking-section-wrapper {
                .booking-basket-container {
                    padding: 50px 0 0 20px !important;
                }
            }
        }

        @include device(l) {
            padding: 52px 80px 90px 50px;
        }
    }



    // button and large screen css in .booking-selection-container > .booking-pitch-filter-container
    .booking-pitch-filter-m-wrapper {
        padding: 30px 20px;
        width: 100%;
        height: 100vh;
        // position: absolute;
        // top: -52px;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99;
        display: none;
        background: $merky-fc-gray;
        color: $on-merky-fc-solid-white;

        &.m-show {
            display: block;
        }

        .booking-pitch-filter-m-close-btn-wrapper {
            width: 24px;
            height: 24px;
            position: absolute;
            top: 15px;
            right: 15px;
            z-index: 1;
            cursor: pointer;

            svg {
                width: 100%;
                height: 100%;
            }

            &:hover svg path {
                fill: $merky-fc-yellow;
            }
        }

        .booking-pitch-filter-m-title-wrapper {
            padding: 0 0 70px;
            display: flex;
            flex-direction: column;
            gap: 3px 0;

            .booking-pitch-filter-m-title {
                color: $on-merky-fc-solid-white;
                text-align: center;
                font-family: $bff-itc-fgs-demi;
                font-size: 72px;
                font-style: normal;
                font-weight: 600;
                line-height: 83%;
                /* 59.76px */
                letter-spacing: -0.72px;
                text-transform: uppercase;
            }
        }

        .booking-pitch-filter-m-sub-title {
            padding: 0 0 50px;
            color: $on-merky-fc-solid-white;
            font-family: $bff-itc-fgs-book;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 1;
            letter-spacing: 2.4px;
            text-transform: uppercase;
        }

        .booking-pitch-filter-m-items-wrapper {
            display: grid;
            grid-template-columns: 1fr;
            gap: 21px 0;

            .booking-pitch-filter-m-item {
                padding: 0 0 15px;
                display: grid;
                grid-template-columns: 1fr 34px;
                border-bottom: 1px solid;

                .booking-pitch-filter-m-item-label {
                    margin: 5px 0 0;
                    height: 28px;
                    color: $on-merky-fc-solid-white;
                    font-family: $bff-itc-fgs-demi;
                    font-size: 36px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 1;
                    /* 29.88px */
                    letter-spacing: -0.36px;
                    text-transform: uppercase;
                }

                .booking-pitch-filter-m-item-checkbox-wrap {
                    width: 100%;
                    height: 100%;
                    cursor: pointer;
                }
            }
        }

        @include device(l, xl, xxl, xxxl) {
            display: none;

            &.m-show {
                display: none;
            }
        }
    }
}


/* Container for venue hire */
.slider-container {
    width: 100%;
    height: 100%;

    .slider-wrap {
        width: 100%;
        height: 100%;

        /* Image */
        .slide-image {
            display: block;
            width: 100%;
            aspect-ratio: 1.676/1;
            object-fit: cover;
            object-position: center;
        }

        /* Navigation Buttons */
        .prev-next-btn {
            padding: 4px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            outline: none;
            border-radius: 50%;
            background: #DAFF00;
            transition: background 0.3s;
            box-shadow: 0px 1px 3px 0px #0000004D, 0px 4px 8px 3px #00000026;
            cursor: pointer;

            .np-icon {
                display: block;
                width: 10px;
                height: 12px;
            }

            &.prev-btn {
                left: 3%;
            }

            &.next-btn {
                right: 3%;
            }
        }
    }

    .venue-room-detail-container {
        padding: 7.003% 4.5%;
        width: 100%;
        height: 100%;
        display: flex;
        gap: 50px 40px;

        .venue-room-detail-wrap {
            flex: 1;

            .venue-room-name {
                margin: 0 0 3%;
                color: $on-primary;
                font-family: $bff-itc-fgs-demi;
                font-size: calc(35px + (70 - 35) * ((100vw - 320px) / (1440 - 320)));
                font-style: normal;
                font-weight: 600;
                line-height: 1;
                letter-spacing: -0.7px;
                text-transform: uppercase;
            }

            .venue-room-description {
                margin: 0 0 10%;
                color: $on-primary-light;
                font-family: $bff-peregrin-denton-light;
                font-size: calc(12px + (16 - 12) * ((100vw - 320px) / (1440 - 320)));
                font-style: normal;
                font-weight: 300;
                line-height: 120%;
                /* 19.2px */
                letter-spacing: 0.8px;
            }

            .button-group {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                gap: 16px;
            }

            .venue-action-button {
                flex: 1 150px;
                padding: 14px 26px;
                width: 100%;
                max-width: 300px;
                height: 45px;
                font-family: $bff-itc-fgs-book;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 1;
                letter-spacing: 1.8px;
                text-transform: uppercase;
                white-space: pre;
                background: $secondary;
                color: $on-secondary-dark;
                border-radius: 16px;
                outline: 0;
                border: 3px solid $border-light-black;
                cursor: pointer;
            }
        }

        .grid-table-wrap {
            flex: 1;

            .grid-table {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                border: 1px dashed rgba(255, 255, 255, 0.5);

                .row-col-group {
                    padding: 0;
                    border-right: 1px dashed rgba(255, 255, 255, 0.5);
                    display: grid;
                    grid-template-rows: subgrid;
                    grid-row: span 4;

                    &:last-child {
                        border: 0;
                    }

                    .th-cell {
                        padding: 10px 14px;
                        background-color: $primary;

                        &:last-child {
                            border: 0;
                        }

                        &-text {
                            color: $on-primary-light;
                            font-family: $bff-itc-fgs-demi;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 1;
                            letter-spacing: 1.3px;
                            text-transform: uppercase;
                        }
                    }

                    .td-cell {
                        padding: 10px 14px;
                        border-top: 1px dashed rgba(255, 255, 255, 0.5);
                        background: $secondary;

                        &-text {
                            color: $on-primary;
                            font-family: $bff-peregrin-denton-light;
                            font-weight: 300;
                            font-size: 13px;
                            line-height: 1;
                        }
                    }
                }
            }
        }

        @include device(xs, s, m) {
            flex-direction: column;

            .grid-table-wrap .grid-table {
                grid-template-columns: 1fr;
                text-align: center;

                .empty-cell {
                    display: none;
                }
            }
        }
    }
}

.venue-address-map-container {
    padding: 7.003% 4.5% 8%;
    width: 100%;
    height: 100%;
    display: flex;
    gap: 80px 40px;
    @include dashedBorder(t);

    .venue-address-text-wrap {
        flex: 1;
        align-self: flex-end;

        .venue-address-text {
            color: $on-primary;
            font-family: $bff-itc-fgs-demi;
            font-weight: 600;
            font-size: calc(16px + (45 - 16) * ((100vw - 320px) / (1440 - 320)));
            line-height: 37.35px;
        }
    }

    .venue-address-map-wrap {
        flex: 1;

        .venue-map-title {
            margin: 0 0 3%;
            font-family: $bff-itc-fgs-book;
            font-weight: 400;
            font-size: 16px;
            line-height: 1;
            letter-spacing: 1px;
        }

        .venue-address-map {
            width: 100%;
            aspect-ratio: 1.765 / 1;

            .venue-address-map-icon {
                display: block;
                width: 100%;
                height: 100%;

                .room-rect {
                    &:hover {
                        fill: $tertiary;

                        +.room-rect-text {
                            fill: $on-tertiary-dark;
                        }
                    }
                }
            }
        }
    }

    @include device(xs, s) {
        flex-wrap: wrap;
        flex-direction: column-reverse;
        gap: 40px;

        .venue-address-text-wrap {
            align-self: unset;

            .venue-address-text {
                font-weight: 400;
                line-height: 19.2px;
            }
        }
    }
}

.venue-enquire-footer-button {
    padding: 14px 26px;
    width: 100%;
    font-family: $bff-itc-fgs-book;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    background: $secondary;
    color: $on-secondary-dark;
    outline: 0;
    border: none;
    border-top: 3px solid $border-light-black;

    &:hover {
        color: $on-tertiary;
        background-color: $tertiary;
        cursor: pointer;
    }
}